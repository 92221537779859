import { ApplicantAddressType, DocumentType, FileType } from './api-types';

export const GET_KYC_INVESTOR_SUCCESS = 'GET_KYC_INVESTOR_SUCCESS';
export const GET_KYC_INVESTOR = 'GET_KYC_INVESTOR';

export const GET_KYC_OTP_SUCCESS = 'GET_KYC_OTP_SUCCESS';
export const GET_KYC_OTP = 'GET_KYC_OTP';

export const VERIFY_KYC_OTP_SUCCESS = 'VERIFY_KYC_OTP_SUCCESS';
export const VERIFY_KYC_OTP = 'VERIFY_KYC_OTP';

export const GET_KYC_APPLICATION_DETAILS_SUCCESS = 'GET_KYC_APPLICATION_DETAILS_SUCCESS';
export const GET_KYC_APPLICATION_DETAILS = 'GET_KYC_APPLICATION_DETAILS';

export const GET_KYC_APPLICATION_REF_IDS_SUCCESS = 'GET_KYC_APPLICATION_REF_IDS_SUCCESS';
export const GET_KYC_APPLICATION_REF_IDS = 'GET_KYC_APPLICATION_REF_IDS';

export const GET_SIGNED_ON_KYC_APPLICATION_SUCCESS = 'GET_SIGNED_ON_KYC_APPLICATION_SUCCESS';
export const GET_SIGNED_ON_KYC_APPLICATION = 'GET_SIGNED_ON_KYC_APPLICATION';

export const GET_ALL_KYC_APPLICATIONS_SUCCESS = 'GET_ALL_KYC_APPLICATIONS_SUCCESS';
export const GET_ALL_KYC_APPLICATIONS = 'GET_ALL_KYC_APPLICATIONS';

export const CREATE_KYC_APPLICATION_SUCCESS = 'CREATE_KYC_APPLICATION_SUCCESS';
export const CREATE_KYC_APPLICATION = 'CREATE_KYC_APPLICATION';

export const GET_KYC_APPLICATION_SUCCESS = 'GET_KYC_APPLICATION_SUCCESS';
export const GET_KYC_APPLICATION = 'GET_KYC_APPLICATION';

export const UPDATE_KYC_APPLICATION_SUCCESS = 'UPDATE_KYC_APPLICATION_SUCCESS';
export const UPDATE_KYC_APPLICATION = 'UPDATE_KYC_APPLICATION';

export const GET_KYC_DIGILOCKER_URL_SUCCESS = 'GET_KYC_DIGILOCKER_URL_SUCCESS';
export const GET_KYC_DIGILOCKER_URL = 'GET_KYC_DIGILOCKER_URL';

export const SET_DEFAULT_ADDRESS_SUCCESS = 'SET_DEFAULT_ADDRESS_SUCCESS';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';

export const GET_ADDRESS_FROM_DIGILOCKER_SUCCESS = 'GET_ADDRESS_FROM_DIGILOCKER_SUCCESS';
export const GET_ADDRESS_FROM_DIGILOCKER = 'GET_ADDRESS_FROM_DIGILOCKER';

export const KYC_INVESTOR_VERIFY_OTP_SUCCESS = 'KYC_INVESTOR_VERIFY_OTP_SUCCESS';
export const KYC_INVESTOR_VERIFY_OTP = 'KYC_INVESTOR_VERIFY_OTP';

export const GET_XML_FILE_SUCCESS = 'GET_XML_FILE_SUCCESS';
export const GET_XML_FILE = 'GET_XML_FILE';

export const GET_KYC_DOCS = 'GET_KYC_DOCS';
export const GET_KYC_DOCS_SUCCESS = 'GET_KYC_DOCS_SUCCESS';

export const REDIRECT_KYC_UPDATE_SUCCESS = 'REDIRECT_KYC_UPDATE_SUCCESS';

export type KycApplicationListParams = {
  limit: number;
  page: number;
  sortBy: string;
  sortOrder: string;
  search: string;
  status: string;
};

export type KycApplicationFilterType = {
  status: string[];
  [key: string]: string[] | string | number | null | boolean;
};

// KYC Application Types
export type UpdateKycApplicationBody = {
  fieldsToUpdate?: { fatherOrSpouseName?: string; kraMobileNumber?: string; kraEmail?: string };
  updateCorrespondenceAddressToCVL?: boolean;
  updatePermanentAddressToCVL?: boolean;
  kyc_documents?: DocumentType[];
  applicantId: string;
  currentStep: number;
};

export type KycApplicant = {
  id: string;
  userId: string;
  kycApplicationId: string;
  pan: string;
  dob: string;
  name: string;
  fatherOrSpouseName: string;
  cvlKraFatherOrSpouseName: string;
  cvlKraEmail: string;
  email: string;
  cvlKraMobileNumber: string;
  phone: string;
  kraEmail: string;
  kraMobileNumber: string;
  updatePermanentAddressToCVL: boolean;
  updateCorrespondenceAddressToCVL: boolean;
  kyc_addresses: Partial<ApplicantAddressType>[];
  kyc_documents: DocumentType[];
  panDataResponseId: string;
  aadhaar_xml_id: string | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  cvlLeegalityDocumentId: string;
  cvlSignedLeegalityDocumentId: string;
  cvlSignedLeegalityDocument: FileType;
  referenceId: string;
};

export type SignDetailsType = {
  status: string;
  url: string;
};

export type KycApplicationResponse = {
  id: string;
  applicationNumber: string;
  digiUrl: string;
  status: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  kyc_applicants: KycApplicant[];
  signDetails: SignDetailsType;
  message?: string;
  editable: boolean;
  currentStep: number | null;
};

export type updatedDigilockerAddress = {
  correspondenceAddress: Partial<ApplicantAddressType>;
  permanentAddress: Partial<ApplicantAddressType>;
};

export type GetDigilockerUrlResponse = {
  uri: string;
};

export type GetAllKycApplicantionsResponse = {
  data: KycApplicationResponse[];
  limit: number;
  page: number;
  pages: number;
  total: number;
};

export type CreateKycPayload = {
  pan: string;
  dob: string;
  phone: string;
  countryCode: string;
  email: string;
};

// KYC Investor Types
export type GetKycOverviewResponse = {
  name: string;
  pan: string;
  phone: string;
  countryCode: string;
  email: string;
  fatherOrSpouceName: string;
  referenceId: string;
};

export type GetKycOtpResponse = {
  verificationToken: string;
  phone: string;
  countryCode: string;
  countryNameAndCode: string;
  email: string;
  referenceId: string;
  investor_type: string;
};

export type VerifyKycOtpPayload = {
  otp: string;
  verificationToken: string;
};

export type VerifyKycOtpResponse = {
  token: string;
};
