import { Box, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { styles } from '../../../Styles/styles';
import { ApplicationHeaderStyles } from '../../commonComponents';
import { KYC_APPLICATION_STATUS, USER_ROLES } from '../../../utils/constant';
import { KycApplicationResponse } from '../../../redux-store/types/kyc';

export function KycPreviewHeader({
  application,
}: {
  application: KycApplicationResponse;
}): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Box sx={styles.ApplicationDetailsCommonLayout} mb={3}>
      <Box sx={{ p: 2 }}>
        <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={2}>
            <Typography sx={ApplicationHeaderStyles.applicationHeader}>
              Application ID: {application.applicationNumber}
            </Typography>
            <Typography
              className={application?.status}
              sx={{
                fontSize: 18,
                fontWeight: 600,
                color: 'text.secondary !important',
                '&.created .MuiTypography-root': {
                  color: 'rgba(51, 127, 201, 0.7) !important',
                },
                '&.completed .MuiTypography-root': {
                  fontWeight: 600,
                  color: 'rgba(35, 133, 63, 0.7) !important',
                },
                '&.rejected .MuiTypography-root': {
                  color: '#BD2932 !important',
                  fontWeight: 500,
                },
              }}>
              Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#B78813',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {KYC_APPLICATION_STATUS[application?.status]}
              </Typography>
            </Typography>
            {[
              USER_ROLES.RM,
              USER_ROLES.DISTRIBUTOR,
              USER_ROLES.SUBDISTRIBUTOR,
              USER_ROLES.AMC_ADMIN,
            ].includes(role) &&
              application?.kyc_applicants[0]?.cvlSignedLeegalityDocument?.url && (
                <Tooltip title={'Download'}>
                  <IconButton
                    sx={{
                      p: 0,
                      pr: 0.5,
                      ':hover': {
                        bgcolor: '#F4FCFC',
                      },
                      cursor: 'pointer',
                      pointerEvents: 'all',
                    }}
                    onClick={() =>
                      window.open(application?.kyc_applicants[0]?.cvlSignedLeegalityDocument?.url)
                    }>
                    <CardMedia
                      component="img"
                      src={'/images/download-icon.png'}
                      alt="Download"
                      sx={{ width: '25px' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
