import {
  Box,
  Button,
  CardMedia,
  Grid,
  Badge,
  IconButton,
  Pagination,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@mui/material';
import { CommonLayout } from '../commonComponents';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useDebounce } from '../../utils/useDebounce';
import ApplicationList from './ApplicationList';
import { getAllKycApplications } from '../../redux-store/actions/kyc';
import { KycFilterDropdown, KycInitialFilters } from './filters/KycFilters';
import {
  GetAllKycApplicantionsResponse,
  KycApplicationFilterType,
  KycApplicationResponse,
} from '../../redux-store/types/kyc';

export default function KycApplications(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [applicationsList, setApplicationsList] = useState<KycApplicationResponse[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const {
    page,
    search: storeSearch,
    status: storeStatus,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [currentPage, setPage] = useState(page);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [filters, setFilters] = useState<KycApplicationFilterType>(
    KycInitialFilters(role, storeStatus?.concat(''))
  );
  const { status } = filters;
  const [search, setSearch] = useState(storeSearch);
  const debounceSearchString = useDebounce(search, 500);

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllKycApplications({
            page: currentPage,
            search,
            status: status.toString(),
          })
        )) as unknown as GetAllKycApplicantionsResponse;
        const { data, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setApplicationsList(data);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
        setLoading(false);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFiltersAnchorEl = async () => {
    try {
      setFiltersAnchorEl(true);
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['status'].filter(
      (filterKey) => (filters[filterKey] as string[])?.length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <KycFilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
          />
        )}
      </>
    );
  };

  return (
    <CommonLayout>
      <>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 18,
              fontWeight: 500,
              mb: { xs: 2, md: 4 },
              p: { xs: '5px 15px', md: '' },
              bgcolor: '#e5f1f8',
              borderRadius: '10px',
            }}>
            All Applications
          </Typography>
          <Grid
            container
            sx={{
              p: '20px 30px',
              pb: 0,
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              bgcolor: 'common.white',
              mb: 1.5,
            }}>
            <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
              <Grid item>
                <FormControl variant="standard" sx={{ display: 'block' }}>
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                    Search Application
                  </InputLabel>
                  <Input
                    id="input-with-icon-adornment"
                    value={search}
                    onChange={({ target: { value } }) => {
                      setPage(1);
                      setSearch(value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchSharpIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    // mr: 2.5,
                    p: 1,
                  }}
                  onClick={() => history.push('/kyc-onboarding')}>
                  Add Applicant
                </Button>
                {filterOptions()}
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <ApplicationList
              isLoading={isLoading}
              applicationsList={applicationsList}
              boxStyles={{ minWidth: 650 }}
            />
          </Box>

          {!!applicationsList.length && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
              <Pagination
                count={noOfPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </Box>
      </>
    </CommonLayout>
  );
}
