import { AnyAction } from 'redux';
import {
  CREATE_KYC_APPLICATION_SUCCESS,
  GET_KYC_APPLICATION_DETAILS_SUCCESS,
  GET_KYC_APPLICATION_SUCCESS,
  KycApplicationResponse,
  SET_DEFAULT_ADDRESS_SUCCESS,
  UPDATE_KYC_APPLICATION_SUCCESS,
} from '../types/kyc';

export type KYCApplicationStateProps = {
  application: KycApplicationResponse | null;
};

const initialState: KYCApplicationStateProps = {
  application: null,
};

function kycReducer(state = initialState, action: AnyAction): Partial<KYCApplicationStateProps> {
  switch (action.type) {
    case CREATE_KYC_APPLICATION_SUCCESS:
    case GET_KYC_APPLICATION_SUCCESS:
    case UPDATE_KYC_APPLICATION_SUCCESS:
    case GET_KYC_APPLICATION_DETAILS_SUCCESS:
    case SET_DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        application: action.body,
      };
    default:
      return state;
  }
}

export default kycReducer;
