/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { encodeBase64 } from '../../utils/utilityFunctions';
import { CALL_API } from '../middleware';
import { investmentDetailsAplicationRef } from '../types/api-types';
import {
  CREATE_KYC_APPLICATION,
  CREATE_KYC_APPLICATION_SUCCESS,
  CreateKycPayload,
  GET_ADDRESS_FROM_DIGILOCKER,
  GET_ADDRESS_FROM_DIGILOCKER_SUCCESS,
  GET_ALL_KYC_APPLICATIONS,
  GET_ALL_KYC_APPLICATIONS_SUCCESS,
  GET_KYC_APPLICATION,
  GET_KYC_APPLICATION_DETAILS,
  GET_KYC_APPLICATION_DETAILS_SUCCESS,
  GET_KYC_APPLICATION_REF_IDS,
  GET_KYC_APPLICATION_REF_IDS_SUCCESS,
  GET_KYC_APPLICATION_SUCCESS,
  GET_KYC_DIGILOCKER_URL,
  GET_KYC_DIGILOCKER_URL_SUCCESS,
  GET_KYC_DOCS,
  GET_KYC_DOCS_SUCCESS,
  GET_KYC_INVESTOR,
  GET_KYC_INVESTOR_SUCCESS,
  GET_KYC_OTP,
  GET_KYC_OTP_SUCCESS,
  GET_SIGNED_ON_KYC_APPLICATION,
  GET_SIGNED_ON_KYC_APPLICATION_SUCCESS,
  GET_XML_FILE,
  GET_XML_FILE_SUCCESS,
  GetAllKycApplicantionsResponse,
  GetDigilockerUrlResponse,
  GetKycOtpResponse,
  GetKycOverviewResponse,
  KYC_INVESTOR_VERIFY_OTP,
  KYC_INVESTOR_VERIFY_OTP_SUCCESS,
  KycApplicationListParams,
  KycApplicationResponse,
  REDIRECT_KYC_UPDATE_SUCCESS,
  SET_DEFAULT_ADDRESS,
  SET_DEFAULT_ADDRESS_SUCCESS,
  SignDetailsType,
  UPDATE_KYC_APPLICATION,
  UPDATE_KYC_APPLICATION_SUCCESS,
  UpdateKycApplicationBody,
  VerifyKycOtpPayload,
  VerifyKycOtpResponse,
  updatedDigilockerAddress,
} from '../types/kyc';

// KYC Investor APIs

export const getKycInvestorWithReferenceId =
  (referenceId: string) =>
  async (dispatch: any): Promise<GetKycOverviewResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/details/${referenceId}`,
        method: 'GET',
        types: [GET_KYC_INVESTOR_SUCCESS, GET_KYC_INVESTOR],
      },
    });
  };

export const getOtpForKycInvestor =
  (referenceId: string) =>
  async (dispatch: any): Promise<GetKycOtpResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/get-otp/${referenceId}`,
        method: 'GET',
        types: [GET_KYC_OTP_SUCCESS, GET_KYC_OTP],
      },
    });
  };

export const verifyOtpForKycInvestor =
  (referenceId: string, body: VerifyKycOtpPayload) =>
  async (dispatch: any): Promise<VerifyKycOtpResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/verify-otp/${referenceId}`,
        method: 'POST',
        types: [KYC_INVESTOR_VERIFY_OTP_SUCCESS, KYC_INVESTOR_VERIFY_OTP],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const getKycApplicationDetailsWithRefId =
  (referenceId: string) =>
  async (dispatch: any): Promise<KycApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/kyc-application-details/${referenceId}`,
        method: 'GET',
        types: [GET_KYC_APPLICATION_DETAILS_SUCCESS, GET_KYC_APPLICATION_DETAILS],
      },
    });
  };

export const getKycApplicationRefIDs =
  (applicationId: string) =>
  async (dispatch: any): Promise<investmentDetailsAplicationRef[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/get-referenceids/${applicationId}`,
        method: 'GET',
        types: [GET_KYC_APPLICATION_REF_IDS_SUCCESS, GET_KYC_APPLICATION_REF_IDS],
      },
    });
  };

export const getSignedByKycInvestor =
  (referenceId: string) =>
  async (dispatch: any): Promise<SignDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/esign/sign/${referenceId}`,
        method: 'POST',
        types: [GET_SIGNED_ON_KYC_APPLICATION_SUCCESS, GET_SIGNED_ON_KYC_APPLICATION],
      },
    });
  };

// KYC Application APIs

export const getAllKycApplications =
  (params: Partial<KycApplicationListParams>) =>
  async (dispatch: any): Promise<GetAllKycApplicantionsResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc`,
        method: 'GET',
        types: [GET_ALL_KYC_APPLICATIONS_SUCCESS, GET_ALL_KYC_APPLICATIONS],
        params: {
          limit: 10,
          sortBy: 'createdAt',
          sortOrder: 'DESC',
          ...params,
        },
      },
    });
  };

export const createKycApplication =
  (body: CreateKycPayload) =>
  async (dispatch: any): Promise<KycApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc`,
        method: 'POST',
        types: [CREATE_KYC_APPLICATION_SUCCESS, CREATE_KYC_APPLICATION],
        body,
      },
    });
  };

export const getKycApplication =
  (applicationId: string) =>
  async (dispatch: any): Promise<KycApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/${applicationId}`,
        method: 'GET',
        types: [GET_KYC_APPLICATION_SUCCESS, GET_KYC_APPLICATION],
      },
    });
  };

export const updateKycApplication =
  (applicationId: string, body: UpdateKycApplicationBody) =>
  async (dispatch: any): Promise<KycApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/${applicationId}`,
        method: 'POST',
        types: [UPDATE_KYC_APPLICATION_SUCCESS, UPDATE_KYC_APPLICATION],
        body,
      },
    });
  };

export const getDigilockerUrl =
  (referenceId: string, token: string, role: string) =>
  async (dispatch: any): Promise<GetDigilockerUrlResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/getDigiLockerUri`,
        method: 'GET',
        types: [GET_KYC_DIGILOCKER_URL_SUCCESS, GET_KYC_DIGILOCKER_URL],
        params: {
          refId: referenceId,
          token: token,
          role,
        },
      },
    });
  };

export const setDefaultAddresses =
  (applicantId: string) =>
  async (dispatch: any): Promise<KycApplicationResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/set-default-addresses/${applicantId}`,
        method: 'GET',
        types: [SET_DEFAULT_ADDRESS_SUCCESS, SET_DEFAULT_ADDRESS],
      },
    });
  };

export const getAddressFetchedFromDigilocker =
  (applicantId: string, addressType: string) =>
  async (dispatch: any): Promise<updatedDigilockerAddress> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/aadhaar-address/${applicantId}/${addressType}`,
        method: 'GET',
        types: [GET_ADDRESS_FROM_DIGILOCKER_SUCCESS, GET_ADDRESS_FROM_DIGILOCKER],
      },
    });
  };

export const getXmlFile =
  (applicantionId: string) =>
  async (dispatch: any): Promise<{ url: string }> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kyc/${applicantionId}/get-xml-file`,
        method: 'GET',
        types: [GET_XML_FILE_SUCCESS, GET_XML_FILE],
      },
    });
  };

// Storing token from digilocker
export const getInvestorTokenFromDigilocker = (tokenObj: { token: string }) => ({
  type: REDIRECT_KYC_UPDATE_SUCCESS,
  tokenObj,
});

// Get MDMS KYC Documents
export const getKYCDocuments =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/kyc_document_master.json',
        method: 'GET',
        types: [GET_KYC_DOCS_SUCCESS, GET_KYC_DOCS],
      },
    });
  };
