import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/auth';
import { KYC_INVESTOR_VERIFY_OTP_SUCCESS, REDIRECT_KYC_UPDATE_SUCCESS } from '../types/kyc';

const initState: Partial<{ token: string | null }> = {
  token: null,
};
function kycInvestorReducer(
  state = initState,
  action: AnyAction
): Partial<{ token: string | null }> {
  switch (action.type) {
    case KYC_INVESTOR_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case REDIRECT_KYC_UPDATE_SUCCESS: {
      const tokenObj = { token: action.tokenObj.token };
      return {
        ...state,
        ...tokenObj,
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default kycInvestorReducer;
