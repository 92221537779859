import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { Gridstyles } from '../ApplicationDetails';
import { SubHeading } from '../investors/components';
import {
  currencyConversion,
  getclIdField,
  getdpIdField,
  isCDSL,
  nameOfBrokerExists,
} from '../../utils/utilityFunctions';
import { DLCLMasters, investorTypeMasters } from '../../utils/constant';
import { styles } from '../../Styles/styles';
import { useMdmsBrokerListData } from '../../utils/useDataMdms';

export default function ContributorDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const [brokerList, setBrokerList] = useState<BrokerList>();
  const brokerMdmsData = useMdmsBrokerListData();
  useEffect(() => {
    (async function () {
      try {
        setBrokerList(brokerMdmsData.BrokerList as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [brokerMdmsData]);

  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
            </Grid>
            {applicants.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name Of Entity'} description={applicant.name} />
                      <KeyValue
                        title={'Date of Registration/Incorporation'}
                        description={formatShortDate(applicant.dateOfBirth)}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'Place of Registration/Incorporation'}
                        description={applicant.cityOfIncorporation}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'CKYC No./CKYC Acknowledge No./KIN No'}
                        description={applicant.ckycNo}
                      />
                      <KeyValue
                        title={'CVL KRA Verified'}
                        description={applicant.kraVerified}
                        descriptionSx={{
                          textTransform: 'capitalize',
                        }}
                      />
                      <KeyValue
                        title={`AML Verified`}
                        description={applicant.amlCheck ? 'Yes' : 'No'}
                      />
                      {applicant.investorType && (
                        <KeyValue
                          title={'Investor Type'}
                          description={investorTypeMasters[applicant.investorType]}
                        />
                      )}
                      <KeyValue title={'PAN'} description={applicant.panNo} />
                      {applicant.kraMobileNumber && (
                        <KeyValue
                          title={'KRA Mobile Number'}
                          description={applicant.kraMobileNumber}
                        />
                      )}
                      {applicant.kraEmailId && (
                        <KeyValue title={'KRA Email Id'} description={applicant.kraEmailId} />
                      )}
                    </Grid>
                  </Gridstyles>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Other Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={21}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      <KeyValue
                        title={'Net Worth'}
                        description={applicant.netWorth}
                        amount={currencyConversion(applicant.netWorth)}
                      />
                      <KeyValue
                        title={'PEP Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue
                        title={'Demat Account Details (for allotment of units)'}
                        description={DLCLMasters[applicant.dlclId || '']}
                      />
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue
                          title={isCDSL(applicant.dlclId || '') ? 'DPCL ID' : 'DP ID'}
                          description={applicant.dpId}
                        />
                      )}
                      {getclIdField(applicant.dlclId || '') && (
                        <KeyValue title={'CL ID'} description={applicant.clId} />
                      )}
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue
                          title={'Name of Broker/Depository Participant (DP)'}
                          description={
                            nameOfBrokerExists(
                              brokerList?.broker_list as Broker[],
                              applicant.nameOfBroker || ''
                            )
                              ? brokerList?.broker_list.find(
                                  (broker) => broker.key === applicant.nameOfBroker
                                )?.value
                              : applicant.nameOfBroker
                          }
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
