import {
  Box,
  Grid,
  IconButton,
  Typography,
  CardMedia,
  Dialog,
  Tooltip,
  Theme,
} from '@mui/material';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { handleDownload } from '../../utils/utilityFunctions';
import { ConfirmationDialog, LoadingDialog } from '../commonComponents';
import React, { useEffect, useState } from 'react';
import { getApplicationDetails } from '../../redux-store/actions/application';
import { useDispatch } from 'react-redux';
import { investmentDetailsAplicationRef } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { KYC_APPLICATION_STATUS } from '../../utils/constant';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import { getKycApplicationRefIDs } from '../../redux-store/actions/kyc';
import { KycApplicationResponse } from '../../redux-store/types/kyc';

export const getTableHeader = (): ColumnType[] => {
  return [
    {
      header: 'Application Number',
      label: 'applicationNumber',
      valueGetter: (row: KycApplicationResponse) => row.applicationNumber || 'N/A',
    },
    {
      header: 'Investor Name',
      valueGetter: ({ kyc_applicants = [] }: KycApplicationResponse) =>
        kyc_applicants[0].name || 'N/A',
    },
    {
      header: 'Investor PAN',
      valueGetter: ({ kyc_applicants = [] }: KycApplicationResponse) =>
        kyc_applicants[0]?.pan || 'N/A',
    },
    {
      header: 'Status',
      renderCell: (row: KycApplicationResponse) => {
        const applicationStatus = KYC_APPLICATION_STATUS[row.status];
        const { kyc_applicants = [] } = row;
        return (
          <Typography
            className={applicationStatus}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 14,
              color: '#B78813',
              '&.Draft': {
                color: 'rgba(51, 127, 201, 0.7)',
              },
              '&.Completed': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected': {
                color: '#BD2932 !important',
                fontWeight: 500,
              },
              '& span': {
                display: 'block',
                color: 'rgba(51, 127, 201, 0.7)',
                mt: 0.5,
              },
            }}
            component={RouterLink}
            to={`/kyc-application-preview/${row.id}`}>
            {applicationStatus}
            {KYC_APPLICATION_STATUS.sent_to_applicant === applicationStatus && (
              <span>
                Leegality Document{' '}
                {kyc_applicants[0].cvlLeegalityDocumentId ? 'generated' : 'pending'}
              </span>
            )}
          </Typography>
        );
      },
    },
  ];
};

export default function ApplicationList({
  isLoading,
  applicationsList,
  boxStyles,
}: {
  isLoading: boolean;
  applicationsList: KycApplicationResponse[];
  boxStyles?: SxProps<Theme>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rejectionDialog, setRejectionDialog] = useState(false);

  const [isDialogLoading, setDialogLoading] = useState(false);
  const [id, setId] = useState('');
  const [tooltip, setTooltip] = useState('Click to Copy');
  const [applicationRefsList, setApplicationRefsList] = useState<investmentDetailsAplicationRef[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState<{ id: string } | null>(null);
  const [applicationsListData, setApplicationsListData] = useState<KycApplicationResponse[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };
  useEffect(() => {
    setApplicationsListData(applicationsList);
  }, [applicationsList]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            getKycApplicationRefIDs(id)
          )) as unknown as investmentDetailsAplicationRef[];
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: KycApplicationResponse): JSX.Element => {
      const [loadingPopup, setLoadingPopup] = useState(false);

      const handleLoadingPopupClose = () => {
        setLoadingPopup(false);
      };

      return (
        <Box display="flex">
          {/* {isGetLinkVisible(row.status) && ( */}
          <Tooltip title="Link">
            <IconButton
              sx={{
                p: 0,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/investor-kyc/${row.kyc_applicants[0].referenceId}/login`
                );
              }}>
              <CardMedia
                component="img"
                src="/images/link-icon.svg"
                alt="Link"
                sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          {/* )} */}
          <Tooltip title={row.editable ? 'Edit' : ''}>
            <IconButton
              onClick={() => {
                row.editable && history.push(`/kyc-application`, { id: row.id });
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                '&.Mui-disabled': {
                  cursor: row.editable ? 'pointer' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
              disabled={!row.editable}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={row.deletable ? 'Delete' : 'Application can`t be Deleted'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => row.deletable && setDeletePopUp({ id: row.id })}
                disabled={!row.deletable}>
                <DeleteOutlineIcon color={row.deletable ? 'error' : 'disabled'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip
            title={
              row?.kyc_applicants[0].cvlSignedLeegalityDocument?.url
                ? 'Download'
                : 'Only Signed Documents can be Downloaded'
            }>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={async () => {
                try {
                  row?.kyc_applicants[0]?.cvlSignedLeegalityDocument?.url &&
                    (await handleDownload(
                      row?.kyc_applicants[0]?.cvlSignedLeegalityDocument?.url as string,
                      `${row.applicationNumber}.pdf`
                    ));
                } catch (e) {
                  setLoadingPopup(false);
                  console.error((e as Error).message);
                }
              }}>
              <CardMedia
                component="img"
                src={
                  row?.kyc_applicants[0]?.cvlSignedLeegalityDocument?.url
                    ? '/images/download-icon.png'
                    : '/images/download-icon-disabled.png'
                }
                alt="Download"
              />
            </IconButton>
          </Tooltip>
          <LoadingDialog
            loadingPopup={loadingPopup}
            onLoadingPopupClose={handleLoadingPopupClose}
            loaderText={'Downloading'}
          />
        </Box>
      );
    },
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        tableHeader={[...getTableHeader(), additionalCell]}
        tableData={applicationsListData}
        rowCustomStyles={{
          '&:last-child td, &:last-child th': { border: 0 },
          '.MuiTableCell-body ': { color: '#686e72' },
          background: 'transparent',
        }}
        boxStyles={boxStyles}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={2}>
          <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
            Get Link
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
        {applicationRefsList &&
          applicationRefsList.map((ref) => {
            return (
              <React.Fragment key={ref.id}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={1}
                  sx={{
                    ':hover': {
                      bgcolor: '#F4FCFC',
                    },
                    ':last-of-type': {
                      mb: 5,
                    },
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '18px',
                      fontWeight: 500,
                    }}>
                    Copy Link For {ref.name}
                  </Typography>
                  <Tooltip title={tooltip}>
                    <IconButton
                      onMouseOver={() => {
                        setTooltip('Copy Link');
                      }}
                      onClick={() => {
                        setTooltip('Copied'),
                          navigator.clipboard.writeText(
                            ref.invitationUrl
                              ? ref?.invitationUrl
                              : `${window.location.origin}/investor-kyc/${ref.referenceId}/login`
                          );
                      }}>
                      <CardMedia
                        component="img"
                        src="/images/link-icon.svg"
                        alt="Link"
                        sx={{ width: '35px' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            );
          })}
      </Dialog>
      <Dialog
        onClose={handleDialogClose}
        open={isDialogLoading}
        sx={{
          '.MuiPaper-root ': {
            p: 4,
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
            Loading...
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        onClose={handleRejectionDialogClose}
        open={rejectionDialog}
        sx={{
          '.MuiPaper-root ': {
            width: { xs: '70%', sm: '70%' },
          },
        }}>
        <IconButton
          onClick={handleRejectionDialogClose}
          sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        {/* {rejectedApplication && (
          <Box
            sx={{
              width: { xs: '80%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontWeight: 500,
              },
              mt: 1,
              mb: 5,
            }}>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 6.7 },
                mt: 1,
              }}>
              Application Id:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.applicationNumber}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 1.5 },
                mt: 1,
              }}>
              Application Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#BD2932',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {POA_APPLICATION_STATUS[rejectedApplication.status]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9 },
                mt: 1,
              }}>
              Rejected on:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {formatShortDateAndTime(rejectedApplication.rejectedAt)}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9.5 },
                mt: 1,
              }}>
              Rejected by:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                mt: 1,
              }}>
              Reason for rejection:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.rejectedReason}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
              onClick={handleRejectionDialogClose}>
              Done
            </Button>
          </Box>
        )} */}
      </Dialog>
      <ConfirmationDialog
        message={'Are you sure you want to delete Application ?'}
        open={deletePopUp !== null}
        setOpen={() => setDeletePopUp(null)}
        onSave={async () => {
          try {
            setDeletePopUp(null);
            // eslint-disable-next-line
            (await dispatch(
              getApplicationDetails(deletePopUp?.id || '', 'delete')
            )) as unknown as KycApplicationResponse;
            setApplicationsListData(
              applicationsListData.filter((application) => application.id !== deletePopUp?.id || '')
            );
            enqueueSnackbar('Application Deleted Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } catch (e) {
            console.error((e as Error).message);
          } finally {
            setDialogLoading(false);
          }
        }}
        onCancel={() => setDeletePopUp(null)}
      />
    </>
  );
}
