import {
  Box,
  CardMedia,
  Stack,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Pagination,
  Dialog,
  Tooltip,
  Button,
  Badge,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ApplicationDownload,
  ApplicationRefs,
  getAllTopups,
  getSpecificTopups,
} from '../redux-store/actions/onBoarding';
import { CommonLayout, ConfirmationDialog, LoadingDialog } from './commonComponents';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { DataTable } from './DataTable';
import {
  APPLICATION_LISTING_STATUS,
  POA_APPLICATION_STATUS,
  REJECTED_ROLES,
  USER_ROLES,
} from '../utils/constant';
import { useSnackbar } from 'notistack';
import { useDebounce } from '../utils/useDebounce';
import { formatShortDateAndTime } from '../utils/date';
import CloseIcon from '@mui/icons-material/Close';
import { RootStateType } from '../redux-store/reducers';
import { GetAllTopUpApplication } from '../redux-store/types/application';
import {
  ApplicationFilterType,
  ApplicationProps,
  investmentDetailsAplicationRef,
} from '../redux-store/types/api-types';
import { getTableHeader } from './dashboard/applicationslist';
import {
  applicationDownloadStatusCheck,
  checkIfApplicationIsNonIndividual,
  getFirstHolderName,
  handleDownload,
  isApplicantsVerified,
  isGetLinkVisible,
} from '../utils/utilityFunctions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { reportDownload } from './Reports/report-utils';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { FilterDropdown, initialFilters } from './investors/filters';
import { Distributor, getDistributorsListForFilters } from '../redux-store/actions';

const topUpheader = getTableHeader(true);

topUpheader.splice(1, 0, {
  header: 'Top Up Number',
  label: 'topupApplicationNumber',
  valueGetter: (row: ApplicationProps) => row.topupApplicationNumber || 'N/A',
});

export const TopUpListComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [topUpApplicationList, setTopUpApplicationList] = useState<ApplicationProps[]>([]);
  const [noOfPages, setNoPages] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  // const [reSendRevokeLoading, setReSendRevokeLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [rejectedApplication, setRejectedApplication] = useState<ApplicationProps>();
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [checkPoaOrNI, setCheckPoaOrNI] = useState(false);
  const [tooltip, setTooltip] = useState('Click to Copy');
  const [applicationRefsList, setApplicationRefsList] = useState<investmentDetailsAplicationRef[]>(
    []
  );
  const [deletePopUp, setDeletePopUp] = useState<{ id: string } | null>(null);
  const history = useHistory();
  const [distributorLoading, setDistributorLoading] = useState(false);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const {
    page,
    search: storeSearch,
    status: storeStatus,
    applicationType: storeApplicationType,
    modeOfHolding: storeModeOfHolding,
    distributorId: storeDistributorId,
    applicationSentBack,
    schemeId: storeSchemeId,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [currentPage, setPage] = useState(page);
  const [search, setSearch] = useState(storeSearch);
  const debounceSearchString = useDebounce(search, 500);
  const [filters, setFilters] = useState<ApplicationFilterType>(
    initialFilters(
      role,
      storeStatus?.concat(
        applicationSentBack ? `${storeStatus.length ? ',' : ''}draft_send_back` : ''
      ),
      storeApplicationType,
      storeModeOfHolding,
      storeDistributorId,
      storeSchemeId
    )
  );
  const { applicationType, distributorId, status, modeOfHolding, schemeId } = filters;

  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setTopUpApplicationList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllTopups({
            search: search,
            page: currentPage,
            applicationType: applicationType.toString(),
            distributorId: distributorId.toString(),
            status: status
              .toString()
              .replace('draft_send_back', '')
              .split(',')
              .filter((ele) => ele)
              .join(','),
            modeOfHolding: modeOfHolding.toString(),
            applicationSentBack: status.includes('draft_send_back') ? true : null,
            schemeId: schemeId.toString(),
          })
        )) as unknown as GetAllTopUpApplication;
        const { topups, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setTopUpApplicationList(topups);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
        setLoading(false);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString, filters]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            ApplicationRefs(id, true)
          )) as unknown as investmentDetailsAplicationRef[];
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: ApplicationProps): JSX.Element => {
      const [loadingPopup, setLoadingPopup] = useState(false);

      const handleLoadingPopupClose = () => {
        setLoadingPopup(false);
      };

      return (
        <Box display="flex">
          {APPLICATION_LISTING_STATUS[row.status] === APPLICATION_LISTING_STATUS.rejected && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/comment.svg"
                alt="comment"
                sx={{ width: '20px' }}
                onClick={() => {
                  setRejectedApplication(row);
                  setRejectionDialog(true);
                }}
              />
            </IconButton>
          )}
          {isGetLinkVisible(row.status) && (
            <Tooltip title="Link">
              <IconButton
                sx={{
                  p: 0,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}
                onClick={() => {
                  setId(row.id as string),
                    setDialogLoading(true),
                    setCheckPoaOrNI(row.hasPOA || checkIfApplicationIsNonIndividual(row));
                }}>
                <CardMedia
                  component="img"
                  src="/images/link-icon.svg"
                  alt="Link"
                  sx={{ width: '30px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isApplicantsVerified(row) ? (
            <Tooltip title={row.editable ? 'Edit' : ''}>
              <IconButton
                onClick={() => {
                  row.editable &&
                    history.push(
                      checkIfApplicationIsNonIndividual(row)
                        ? `/non-individual-application`
                        : `/application`,
                      { id: row.id, topUpApplication: row.topupInitiated }
                    );
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.editable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                disabled={!row.editable}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={'Top Up'}>
              <IconButton
                onClick={async () => {
                  try {
                    // const response = (await dispatch(
                    //   topUpInit(row.id)
                    // )) as unknown as ApplicationProps;
                    history.push('/onboarding/enter-pan-number', {
                      application: row,
                      firstHolderName: getFirstHolderName(row.applicants),
                      index: row.applicants.findIndex(
                        (applicant) => applicant.kraVerified === 'no'
                      ),
                      fromTopUp: true,
                    });
                  } catch (e) {
                    console.log((e as Error).message);
                  }
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}>
                <PostAddIcon />
              </IconButton>
            </Tooltip>
          )}
          {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={row.deletable ? 'Delete' : 'Application can`t be Deleted'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => row.deletable && setDeletePopUp({ id: row.id })}
                disabled={!row.deletable}>
                <DeleteOutlineIcon color={row.deletable ? 'error' : 'disabled'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                ? 'Download'
                : 'Only Signed Documents can be Downloaded'
            }>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={async () => {
                try {
                  if (applicationDownloadStatusCheck(row.status || '')) {
                    setLoadingPopup(true);
                    // eslint-disable-next-line
                    const res = (await dispatch(
                      ApplicationDownload(row.id, !!row?.topupInitiated)
                    )) as unknown as Blob;
                    reportDownload(res as unknown as Blob, `${row.topupApplicationNumber}.pdf`);
                    setLoadingPopup(false);
                    return;
                  }
                  row?.signedLeegalityFile?.url &&
                    (await handleDownload(
                      row?.signedLeegalityFile?.url as string,
                      `${row.topupApplicationNumber}.pdf`
                    ));
                  // window.open(row?.signedLeegalityFile?.url);
                } catch (e) {
                  setLoadingPopup(false);
                  console.error((e as Error).message);
                }
              }}>
              <CardMedia
                component="img"
                src={
                  applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                    ? '/images/download-icon.png'
                    : '/images/download-icon-disabled.png'
                }
                alt="Download"
                //sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          <LoadingDialog
            loadingPopup={loadingPopup}
            onLoadingPopupClose={handleLoadingPopupClose}
            loaderText={'Downloading'}
          />
        </Box>
      );
    },
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false), setId('');
  };

  const handleFiltersAnchorEl = async () => {
    try {
      setFiltersAnchorEl(true);
      setDistributorLoading(true);
      const distributorList = (await dispatch(
        getDistributorsListForFilters()
      )) as unknown as Distributor[];
      setDistributors(distributorList);
      setDistributorLoading(false);
    } catch (e) {
      console.error((e as Error).message);
      setDistributorLoading(false);
    }
  };

  const handleFiltersAnchorElClose = () => {
    setFiltersAnchorEl(false);
  };

  const filterOptions = (): JSX.Element => {
    const badgeCount = ['applicationType', 'modeOfHolding', 'status', 'distributorId'].filter(
      (filterKey) => (filters[filterKey] as string[]).length
    ).length;
    return (
      <>
        <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            distributors={distributors}
            loader={distributorLoading}
          />
        )}
      </>
    );
  };
  // const handleLoadingPopupClose = () => {
  //   setReSendRevokeLoading(false);
  // };
  return (
    <CommonLayout>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
          }}>
          View Top Ups
        </Typography>
        <Grid
          container
          sx={{
            p: '20px 30px',
            pb: 0,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            bgcolor: 'common.white',
            mb: 1.5,
          }}>
          <Grid
            item
            container
            sx={{
              width: 'unset',
              alignItems: 'flex-end',
              gap: 2,
              p: '20px 30px',
              bgcolor: 'common.white',
              mb: 1.5,
            }}>
            <Grid item>
              <FormControl variant="standard" sx={{ display: 'block' }}>
                <InputLabel
                  htmlFor="input-with-icon-adornment"
                  sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                  Search Top Up Application
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  value={search}
                  onChange={({ target: { value } }) => {
                    setPage(1);
                    setSearch(value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchSharpIcon sx={{ color: 'primary.main' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sx={{ width: 'unset', alignItems: 'flex-end', gap: 2, pb: 3 }}>
            <Grid item>{filterOptions()}</Grid>
            <Grid item>{/* <FilterMenu /> */}</Grid>
          </Grid>
        </Grid>
        <Box>
          <DataTable
            isLoading={isLoading}
            tableHeader={[...topUpheader, additionalCell]}
            tableData={topUpApplicationList}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
              '.MuiTableCell-body ': { color: '#686e72' },
              background: 'transparent',
            }}
            boxStyles={{ minWidth: 650 }}
          />
        </Box>
        {!!topUpApplicationList.length && (
          <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
            <Pagination
              count={noOfPages}
              page={currentPage}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        )}
        {rejectedApplication && (
          <Box
            sx={{
              width: { xs: '80%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontWeight: 500,
              },
              mt: 1,
              mb: 5,
            }}>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 6.7 },
                mt: 1,
              }}>
              Application Id:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.applicationNumber}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 1.5 },
                mt: 1,
              }}>
              Application Status:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#BD2932',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {POA_APPLICATION_STATUS[rejectedApplication.status]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9 },
                mt: 1,
              }}>
              Rejected on:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {formatShortDateAndTime(rejectedApplication.rejectedAt)}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                ml: { sm: 9.5 },
                mt: 1,
              }}>
              Rejected by:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                letterSpacing: '0.4px',
                mt: 1,
              }}>
              Reason for rejection:{' '}
              <Typography
                component="span"
                sx={{
                  color: '#337FC9',
                  fontSize: '16px',
                  letterSpacing: '0.4px',
                  ml: 0.5,
                }}>
                {rejectedApplication.rejectedReason}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
              onClick={handleRejectionDialogClose}>
              Done
            </Button>
          </Box>
        )}
        <Dialog
          onClose={handleDialogClose}
          open={isDialogLoading}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 20,
                fontWeight: 500,
              },
            }}>
            <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
              Loading...
            </Typography>
          </Box>
        </Dialog>
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={4}
            pb={2}>
            <Typography sx={{ color: 'primary.main', fontSize: '20px', fontWeight: 500 }}>
              Get Link
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>
          {applicationRefsList &&
            applicationRefsList.map((ref) => {
              return (
                <React.Fragment key={ref.id}>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px={4}
                    py={1}
                    sx={{
                      ':hover': {
                        bgcolor: '#F4FCFC',
                      },
                      ':last-of-type': {
                        mb: 5,
                      },
                    }}>
                    <Typography
                      sx={{
                        color: 'rgba(41, 49, 57, 0.7)',
                        fontSize: '18px',
                        fontWeight: 500,
                      }}>
                      Copy Link For {ref.name}
                    </Typography>
                    <Tooltip title={tooltip}>
                      <IconButton
                        onMouseOver={() => {
                          setTooltip('Copy Link');
                        }}
                        onClick={() => {
                          setTooltip('Copied'),
                            navigator.clipboard.writeText(
                              checkPoaOrNI
                                ? ref.invitationUrl
                                : ref?.signatureBy &&
                                  ('fundManager' === ref.signatureBy ||
                                    USER_ROLES.TRUSTEE === ref.signatureBy)
                                ? ref.invitationUrl
                                : `${window.location.origin}/topup/${ref.referenceId}/details`
                            );
                        }}>
                        <CardMedia
                          component="img"
                          src="/images/link-icon.svg"
                          alt="Link"
                          sx={{ width: '35px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              );
            })}
        </Dialog>
        <Dialog
          onClose={handleRejectionDialogClose}
          open={rejectionDialog}
          sx={{
            '.MuiPaper-root ': {
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton
            onClick={handleRejectionDialogClose}
            sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          {rejectedApplication && (
            <Box
              sx={{
                width: { xs: '80%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontWeight: 500,
                },
                mt: 1,
                mb: 5,
              }}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  letterSpacing: '0.4px',
                  ml: { sm: 6.7 },
                  mt: 1,
                }}>
                Application Id:{' '}
                <Typography
                  component="span"
                  sx={{
                    color: '#337FC9',
                    fontSize: '16px',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {rejectedApplication.applicationNumber}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  letterSpacing: '0.4px',
                  ml: { sm: 1.5 },
                  mt: 1,
                }}>
                Application Status:{' '}
                <Typography
                  component="span"
                  sx={{
                    color: '#BD2932',
                    fontSize: '16px',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {POA_APPLICATION_STATUS[rejectedApplication.status]}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  letterSpacing: '0.4px',
                  ml: { sm: 9 },
                  mt: 1,
                }}>
                Rejected on:{' '}
                <Typography
                  component="span"
                  sx={{
                    color: '#337FC9',
                    fontSize: '16px',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {formatShortDateAndTime(rejectedApplication.rejectedAt)}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  letterSpacing: '0.4px',
                  ml: { sm: 9.5 },
                  mt: 1,
                }}>
                Rejected by:{' '}
                <Typography
                  component="span"
                  sx={{
                    color: '#337FC9',
                    fontSize: '16px',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {REJECTED_ROLES[rejectedApplication.rejectedByRole]}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '18px',
                  letterSpacing: '0.4px',
                  mt: 1,
                }}>
                Reason for rejection:{' '}
                <Typography
                  component="span"
                  sx={{
                    color: '#337FC9',
                    fontSize: '16px',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                  }}>
                  {rejectedApplication.rejectedReason}
                </Typography>
              </Typography>
              <Button
                variant="contained"
                sx={{ ml: '32%', lineHeight: 1.1, width: '30%', mt: 3 }}
                onClick={handleRejectionDialogClose}>
                Done
              </Button>
            </Box>
          )}
        </Dialog>
        <ConfirmationDialog
          message={'Are you sure you want to delete Application ?'}
          open={deletePopUp !== null}
          setOpen={() => setDeletePopUp(null)}
          onSave={async () => {
            try {
              setDeletePopUp(null);
              // eslint-disable-next-line
              (await dispatch(
                getSpecificTopups(Number(deletePopUp?.id), 'delete')
              )) as unknown as ApplicationProps;
              setDialogLoading(true);
              const response = (await dispatch(
                getAllTopups({ search: search, page: currentPage })
              )) as unknown as GetAllTopUpApplication;
              const { topups, pages } = response || {};
              setTopUpApplicationList(topups);
              setNoPages(pages);
              enqueueSnackbar('Application Deleted Successfully', {
                variant: 'success',
                autoHideDuration: 3000,
              });
            } catch (e) {
              console.error((e as Error).message);
            } finally {
              setDialogLoading(false);
            }
          }}
          onCancel={() => setDeletePopUp(null)}
        />
      </Box>
    </CommonLayout>
  );
};
