import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { styles } from '../../../Styles/styles';
import { SubHeading } from '../../investors/components';
import { Gridstyles } from '../../ApplicationDetails';
import { KeyValue } from '../../ApplicationDetails/contributor';
import { checkAddressField } from '../../../utils/utilityFunctions';
import { KycApplicant } from '../../../redux-store/types/kyc';

export default function KycAddressPreview({
  applicants = [],
}: {
  applicants: Partial<KycApplicant>[];
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Address Details
            </Typography>
            {applicants.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                    {'Address Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={2}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {!!applicant?.kyc_addresses?.length &&
                        applicant?.kyc_addresses.map((address) => (
                          <React.Fragment key={address.id}>
                            <KeyValue
                              title={`${address.address_type} Address`}
                              description={`${checkAddressField(
                                address.address1
                              )}${checkAddressField(address.address2)}
                              ${checkAddressField(address.city)}${checkAddressField(
                                address.state
                              )}${checkAddressField(address.country)}${address.pincode}`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
