import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import {
  Formik,
  FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { MFTextField } from '../../lib/formik';
import {
  ApplicationProps,
  JointholderRelationMaster,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  APPLICATION_TYPE,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  defaultCurrency,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  currencyConversion,
  getStep,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
  saveForLater,
  setUpFeeCalculations,
  statusRoleBased,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { useMdmsJointHolderRelationsData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';
import MFCheckbox from '../../lib/formik/Checkbox';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  aggregateAmount: 0,
  topupAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  isAccreditedInvestor: false,
  hurdleRateApplicable: '',
  hurdleRate: 0,
  performanceFeeApplicable: '',
  performanceFee: 0,
  exitChargeApplicable: '',
  exitCharge: 0,
  managementFeeApplicable: '',
  exitChargeType: '',
  hurdleRateType: '',
  performanceFeeType: '',
  managementFeeType: '',
  placementFeeApplicable: '',
  placementFeeType: '',
  placementFee: 0,
  operatingExpenseApplicable: '',
  operatingExpenses: 0,
  operatingExpenseType: '',
  setupFeeType: '',
  setupFeeApplicable: '',
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  saveType: 'save and proceed',
};

const removeErrors = (ifTopUp: boolean) => {
  return {
    isAccreditedInvestor: false,
    schemeId: false,
    planId: false,
    [ifTopUp ? 'topupAmount' : 'commitmentAmount']: false,
    aggregateAmount: false,
    contributionChequeAmount: false,
    setupFeePercentage: false,
    managementFeesRate: false,
    setupFeeAmount: false,
    setupFeeGST: false,
    totalSetupFee: false,
    setupFeeTDS: false,
    netSetupFeeLessTDS: false,
    totalAmountPayable: false,
    hurdleRateApplicable: false,
    hurdleRate: false,
    performanceFeeApplicable: false,
    performanceFee: false,
    exitChargeApplicable: false,
    exitCharge: false,
    managementFeeApplicable: false,
    managementFeeType: false,
    hurdleRateType: false,
    exitChargeType: false,
    performanceFeeType: false,
    placementFee: false,
    placementFeeApplicable: false,
    placementFeeType: false,
    operatingExpenses: false,
    operatingExpenseApplicable: false,
    operatingExpenseType: false,
    setupFeeApplicable: false,
    setupFeeType: false,
  };
};

const InvestmentDetails = ({
  values,
  setSelectedPlan,
  setValues,
  isFieldDisabled,
  getClassPlanOptionsOrDetails,
  selectedFund,
  selectedPlan,
  checkTopUpSection,
  ifTopUp,
}: {
  values: Values;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Partial<ClassPlanProps> | null>>;
  setValues: FormikHelpers<Values>['setValues'];
  isFieldDisabled: boolean;
  getClassPlanOptionsOrDetails: (
    arg1: boolean,
    arg2?: string
  ) =>
    | string
    | number
    | boolean
    | ClassPlanProps[]
    | {
        key: string;
        value: number;
      }[];
  selectedFund: FundProps | null;
  selectedPlan: Partial<ClassPlanProps> | null;
  checkTopUpSection: boolean;
  ifTopUp: boolean;
}) => {
  const topUpLabel = ifTopUp && checkTopUpSection;
  const { setTouched, touched } = useFormikContext<Values>();
  return (
    <>
      {!checkTopUpSection && ifTopUp ? (
        <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
          <MFTextField
            name={'commitmentAmount'}
            label={`Existing Commitment Amount *`}
            type="number"
            placeholder="Enter Existing Commitment Amount"
            onKeyDown={(e) => {
              allowOnlyNumbers(e);
            }}
            // onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            //   const feeAmountObj = setUpFeeCalculations(
            //     Number(value) || 0,
            //     values.setupFeePercentage || 0
            //   );
            //   const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
            //   setValues({
            //     ...values,
            //     commitmentAmount: value ? Number(value) : null,
            //     setupFeeAmount: feeAmountObj.feeAmount,
            //     setupFeeGST: feeAmountObj.feeGst,
            //     totalSetupFee: feeAmountObj.totalSetupFee,
            //     netSetupFeeLessTDS: netSetupFee,
            //     totalAmountPayable: values.contributionChequeAmount || Number(value),
            //   });
            // }}
            trimOnBlur={false}
            disabled={ifTopUp && !checkTopUpSection}
          />
          {values.commitmentAmount != 0 && (
            <Typography sx={{ fontSize: 13 }}>
              {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
            </Typography>
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={`planId`}
              label={topUpLabel ? 'Top Up Plan(Unit Class) *' : `Class (Plan) *`}
              items={
                getClassPlanOptionsOrDetails(values.isAccreditedInvestor as boolean) as [
                  { key: string; value: string | number }
                ]
              }
              disabled={!values.schemeId || isFieldDisabled || (ifTopUp && !checkTopUpSection)}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p?.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: ifTopUp ? values.commitmentAmount : 0,
                  topupAmount: ifTopUp ? 0 : null,
                  aggregateAmount: ifTopUp ? Number(values?.commitmentAmount) : null,
                  contributionChequeAmount: 0,
                  setupFeePercentage: selectedPlan?.setupFee,
                  managementFeesRate: selectedPlan?.defaultManagementFee,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  hurdleRateApplicable: selectedPlan?.hurdleRateApplicable,
                  hurdleRate: selectedPlan?.defaultHurdleRate || 0,
                  performanceFeeApplicable: selectedPlan?.performanceFeeApplicable,
                  performanceFee: selectedPlan?.defaultPerformanceFee || 0,
                  exitChargeApplicable: selectedPlan?.exitChargeApplicable,
                  exitCharge: selectedPlan?.defaultExitCharge || 0,
                  managementFeeApplicable: selectedPlan?.managementFeeApplicable,
                  managementFeeType: selectedPlan?.managementFeeType,
                  hurdleRateType: selectedPlan?.hurdleRateType,
                  exitChargeType: selectedPlan?.exitChargeType,
                  performanceFeeType: selectedPlan?.performanceFeeType,
                  placementFee: selectedPlan?.defaultPlacementFee || 0,
                  placementFeeApplicable: selectedPlan?.placementFeeApplicable,
                  placementFeeType: selectedPlan?.placementFeeType,
                  operatingExpenses: selectedPlan?.defaultOperatingExpenses || 0,
                  operatingExpenseApplicable: selectedPlan?.operatingExpenseApplicable,
                  operatingExpenseType: selectedPlan?.operatingExpenseType,
                  setupFeeApplicable: selectedPlan?.setupFeeApplicable,
                  setupFeeType: selectedPlan?.setupFeeType,
                });
                setTouched({
                  ...touched,
                  ...removeErrors(ifTopUp),
                  schemeId: touched.schemeId,
                  isAccreditedInvestor: touched.isAccreditedInvestor,
                });
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={topUpLabel ? 'topupAmount' : 'commitmentAmount'}
              label={topUpLabel ? 'Top Up Commitment Amount *' : 'Commitment Amount *'}
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(value) || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  commitmentAmount: ifTopUp
                    ? values.commitmentAmount
                    : value
                    ? Number(value)
                    : null,
                  aggregateAmount: ifTopUp ? Number(value) + Number(values.commitmentAmount) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || Number(value),
                  topupAmount: ifTopUp ? Number(value) : null,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {(ifTopUp ? values.topupAmount : values.commitmentAmount) != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(
                  ifTopUp ? values.topupAmount : values.commitmentAmount,
                  selectedPlan?.currency
                )}
              </Typography>
            )}
          </Grid>
          {isFieldApplicable(values?.managementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'managementFeesRate'}
                label={`Management Fees Rate`}
                placeholder="Enter Management Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.managementFeeType || '',
                    values?.managementFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.placementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'placementFee'}
                label="Placement Fees Rate *"
                placeholder="Enter Placement Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.placementFeeType || '',
                    values?.placementFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}

          {isFieldApplicable(values?.operatingExpenseApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'operatingExpenses'}
                label="Operating Expense Rate *"
                placeholder="Enter Operating Expense Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.operatingExpenseType || '',
                    values?.operatingExpenseApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}
          {!ifTopUp && (
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'contributionChequeAmount'}
                label={`Contribution Amount *`}
                placeholder="Enter Contribution Amount"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const feeAmountObj = setUpFeeCalculations(
                    values.commitmentAmount || 0,
                    values.setupFeePercentage || 0
                  );
                  const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                  setValues({
                    ...values,
                    contributionChequeAmount: value ? Number(value) : null,
                    setupFeeAmount: feeAmountObj.feeAmount,
                    setupFeeGST: feeAmountObj.feeGst,
                    totalSetupFee: feeAmountObj.totalSetupFee,
                    netSetupFeeLessTDS: netSetupFee,
                    totalAmountPayable: Number(value) || values.commitmentAmount,
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled || !values.planId}
              />
              {values.contributionChequeAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.contributionChequeAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
          )}
          {isFieldApplicable(values?.setupFeeApplicable || '') && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'setupFeePercentage'}
                  label="Setup Fee Percentage *"
                  type="number"
                  placeholder="Enter Setup Fee Percentage"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const feeAmountObj = setUpFeeCalculations(
                      values.commitmentAmount || 0,
                      Number(value)
                    );
                    const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                    setValues({
                      ...values,
                      setupFeePercentage: value ? Number(value) : null,
                      setupFeeAmount: feeAmountObj.feeAmount,
                      setupFeeGST: feeAmountObj.feeGst,
                      totalSetupFee: feeAmountObj.totalSetupFee,
                      netSetupFeeLessTDS: netSetupFee,
                      totalAmountPayable:
                        values.contributionChequeAmount || values.commitmentAmount,
                    });
                  }}
                  trimOnBlur={false}
                  disabled={
                    isFieldDisabled ||
                    !isFieldVariable(
                      values?.setupFeeType || '',
                      values?.setupFeeApplicable || ''
                    ) ||
                    !values.planId ||
                    ifTopUp
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeAmount'}
                  label="Setup Fee Amount"
                  type="number"
                  placeholder="Enter Setup Fee Amount"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeAmount != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeAmount, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeGST'}
                  label="GST (GST On Setup Fee Amount)"
                  type="number"
                  placeholder="Enter GST"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeGST != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeGST, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'totalSetupFee'}
                  label="Total Setup Fee"
                  type="number"
                  placeholder="Enter Total Setup Fee"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.totalSetupFee != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.totalSetupFee, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeTDS'}
                  label="TDS"
                  type="number"
                  placeholder="Enter TDS"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const setupFeeLessTDS = Number(values.totalSetupFee || 0) - Number(value);
                    setValues({
                      ...values,
                      setupFeeTDS: value ? Number(value) : null,
                      netSetupFeeLessTDS: setupFeeLessTDS,
                      totalAmountPayable:
                        values.contributionChequeAmount || values.commitmentAmount,
                    });
                  }}
                  trimOnBlur={false}
                  disabled={isFieldDisabled || !values.planId || ifTopUp}
                />
                {values.setupFeeTDS != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeTDS, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'netSetupFeeLessTDS'}
                  label="Setup Fee Payable"
                  type="number"
                  placeholder="Enter Setup Fee Payable"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.netSetupFeeLessTDS != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.netSetupFeeLessTDS, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {isFieldApplicable(values?.hurdleRateApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'hurdleRate'}
                label={`Hurdle Rate *`}
                placeholder="Enter Hurdle Rate"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.hurdleRateType || '',
                    values?.hurdleRateApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.performanceFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'performanceFee'}
                label={`Performance Fee *`}
                placeholder="Enter Performance Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.performanceFeeType || '',
                    values?.performanceFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.exitChargeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'exitCharge'}
                label={`Exit Charge`}
                placeholder="Enter Exit Charge"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.exitChargeType || '',
                    values?.exitChargeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId ||
                  ifTopUp
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label={'Total Amount Payable'}
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {ifTopUp && checkTopUpSection && (
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'aggregateAmount'}
                label={`Aggregate Commitment Amount`}
                type="number"
                placeholder="Enter Aggregate Commitment Amount"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.aggregateAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.aggregateAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const { jointHolderRelations } = useMdmsJointHolderRelationsData();

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    currency = defaultCurrency,
    maxExitCharge = 100,
    maxHurdleRate = 100,
    maxManagementFee = 100,
    maxPerformanceFee = 100,
    minExitCharge = 0,
    minHurdleRate = 0,
    minManagementFee = 0,
    minPerformanceFee = 0,
    maxPlacementFee = 0,
    maxOperatingExpense = 0,
    minPlacementFee = 0,
    minOperatingExpense = 0,
    minSetupFee = 0,
    maxSetupFee = 0,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      currency = defaultCurrency,
      accreditedMinCommitmentAmount = 0,
      maxExitCharge = 100,
      maxHurdleRate = 100,
      maxManagementFee = 100,
      maxPerformanceFee = 100,
      minExitCharge = 0,
      minHurdleRate = 0,
      minManagementFee = 0,
      minPerformanceFee = 0,
      maxPlacementFee = 100,
      minPlacementFee = 0,
      maxOperatingExpense = 100,
      minOperatingExpense = 0,
      minSetupFee = 0,
      maxSetupFee = 100,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            currency,
            accreditedMinCommitmentAmount: Number(accreditedMinCommitmentAmount),
            maxExitCharge: maxExitCharge === 0 || maxExitCharge ? Number(maxExitCharge) : 100,
            maxHurdleRate: maxHurdleRate === 0 || maxHurdleRate ? Number(maxHurdleRate) : 100,
            maxManagementFee:
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100,
            maxPerformanceFee:
              maxPerformanceFee === 0 || maxPerformanceFee ? Number(maxPerformanceFee) : 100,
            minExitCharge: Number(minExitCharge),
            minHurdleRate: Number(minHurdleRate),
            minManagementFee: Number(minManagementFee),
            minPerformanceFee: Number(minPerformanceFee),
            maxPlacementFee:
              maxPlacementFee === 0 || maxPlacementFee ? Number(maxPlacementFee) : 100,
            minPlacementFee: Number(minPlacementFee),
            maxOperatingExpense:
              maxOperatingExpense === 0 || maxOperatingExpense ? Number(maxOperatingExpense) : 100,
            minOperatingExpense: Number(minOperatingExpense),
            maxSetupFee: maxSetupFee === 0 || maxSetupFee ? Number(maxSetupFee) : 100,
            minSetupFee: Number(minSetupFee),
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || null,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      isAccreditedInvestor: application?.isAccreditedInvestor || false,
      exitChargeApplicable: application?.exitChargeApplicable || '',
      exitCharge: application?.exitCharge || 0,
      hurdleRateApplicable: application?.hurdleRateApplicable || '',
      hurdleRate: application?.hurdleRate || 0,
      performanceFeeApplicable: application?.performanceFeeApplicable || '',
      performanceFee: application?.performanceFee || 0,
      managementFeeApplicable: application?.managementFeeApplicable || '',
      managementFeeType: application?.managementFeeType || '',
      exitChargeType: application?.exitChargeType || '',
      hurdleRateType: application?.hurdleRateType || '',
      performanceFeeType: application?.performanceFeeType || '',
      placementFeeType: application?.placementFeeType || '',
      placementFeeApplicable: application?.placementFeeApplicable || '',
      placementFee: application?.placementFee || 0,
      operatingExpenseType: application?.operatingExpenseType || '',
      operatingExpenseApplicable: application?.operatingExpenseApplicable || '',
      operatingExpenses: application?.operatingExpenses || 0,
      setupFeeType: application?.setupFeeType || '',
      setupFeeApplicable: application?.setupFeeApplicable || '',
      aggregateAmount: application?.topupInitiated ? application?.aggregateAmount : null,
      topupAmount: application?.topupInitiated ? application?.topupAmount : null,
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
        topupApplicationNumber,
        topupAmount: existingTopupAmount,
        aggregateAmount: existingAggregateAmount,
        ...existingApplicationRest
      } = application || {};
      const { saveType, topupAmount, aggregateAmount, ...rest } = values;

      const updatedApplication = {
        ...existingApplicationRest,
        ...rest,
        id,
        applicant1ReferenceId,
        currentStep,
        applicationType,
        applicationNumber,
        topupApplicationNumber,
        schemeId: rest.schemeId || null,
        planId: rest.planId || null,
        contributionChequeAmount: topupApplicationNumber
          ? null
          : Number(rest.contributionChequeAmount) || 0,
        // setupFeePercentage: Number(rest.setupFeePercentage),
        managementFeesRate: isFieldApplicable(rest?.managementFeeApplicable || '')
          ? Number(rest.managementFeesRate)
          : null,
        hurdleRate: isFieldApplicable(rest?.hurdleRateApplicable || '')
          ? Number(rest.hurdleRate)
          : null,
        performanceFee: isFieldApplicable(rest?.performanceFeeApplicable || '')
          ? Number(rest.performanceFee)
          : null,
        exitCharge: isFieldApplicable(rest?.exitChargeApplicable || '')
          ? Number(rest.exitCharge)
          : null,
        managementFeeType: rest?.managementFeeType || null,
        exitChargeType: rest?.exitChargeType || null,
        hurdleRateType: rest?.hurdleRateType || null,
        performanceFeeType: rest?.performanceFeeType || null,
        ...(application?.topupInitiated && {
          aggregateAmount: aggregateAmount || null,
          topupAmount: topupAmount || null,
        }),
        placementFee: isFieldApplicable(rest?.placementFeeApplicable || '')
          ? Number(rest.placementFee)
          : null,
        operatingExpenses: isFieldApplicable(rest?.operatingExpenseApplicable || '')
          ? Number(rest.operatingExpenses)
          : null,
        setupFeePercentage: isFieldApplicable(rest?.setupFeeApplicable || '')
          ? Number(rest.setupFeePercentage)
          : null,
      };
      const checkApplication = applicationComparison(
        {
          ...existingApplicationRest,
          id,
          applicant1ReferenceId,
          currentStep,
          applicationType,
          applicationNumber,
          topupApplicationNumber,
          setupFeePercentage: Number(application?.setupFeePercentage),
          managementFeeType: application?.managementFeeType || null,
          exitChargeType: application?.exitChargeType || null,
          hurdleRateType: application?.hurdleRateType || null,
          performanceFeeType: application?.performanceFeeType || null,
          placementFee: Number(application?.placementFee),
          operatingExpenses: Number(application?.operatingExpenses),
          ...(application?.topupInitiated && {
            aggregateAmount: existingAggregateAmount || null,
            topupAmount: existingTopupAmount || null,
          }),
        },
        {
          ...updatedApplication,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 4 : 3)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > (RiskProfileEnabled ? 8 : 7)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedApplication,
              status: statusRoleBased(role, application),
              currentStep:
                applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? RiskProfileEnabled
                    ? getStep(5, isSaveLater)
                    : getStep(4, isSaveLater)
                  : RiskProfileEnabled
                  ? getStep(9, isSaveLater)
                  : getStep(8, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push('document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push('document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId &&
          (!application.scheme.isActive ||
            !(response.map((res) => res?.id?.toString()) as unknown as string[]).includes(
              application?.schemeId?.toString()
            ))
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund?.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund?.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund?.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (isAccreditedInvestor: boolean, detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }

    const plansCombinedWithApplicationPlan = selectedFund?.plans?.some(
      (plan) => Number(plan?.id) === Number(application?.planId)
    )
      ? selectedFund?.plans
      : application?.schemeId?.toString() === selectedFund?.id?.toString()
      ? selectedFund?.plans?.concat(application?.plan as unknown as ClassPlanProps)
      : selectedFund?.plans;
    const filteredPlans = (
      isAccreditedInvestor
        ? plansCombinedWithApplicationPlan?.filter((plan) =>
            isAccreditedInvestorApplicable(plan.accreditedInvestorApplicable)
          )
        : plansCombinedWithApplicationPlan
    )?.filter((ele) => ele);
    return (
      filteredPlans?.map((plan) => ({
        key: plan?.planDescription,
        value: Number(plan?.id),
      })) || []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        setJointHoldersData(jointHolderRelations);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1 && jointHoldersData
            ? (updatedCommitmentAmount(
                Number(minCommitmentAmount),
                application.applicants,
                jointHoldersData
              ) as number)
            : minCommitmentAmount;
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              (values?.isAccreditedInvestor
                ? selectedPlan?.accreditedMinCommitmentAmount || 0
                : minCommitmentAmountBasedOnJointHolders) as number,
              maxCommitmentAmount || 0,
              minContributionPercentage || 0,
              currency || '',
              values?.saveType,
              maxExitCharge === 0 || maxExitCharge ? Number(maxExitCharge) : 100,
              maxHurdleRate === 0 || maxHurdleRate ? Number(maxHurdleRate) : 100,
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100,
              maxPerformanceFee === 0 || maxPerformanceFee ? Number(maxPerformanceFee) : 100,
              maxPlacementFee === 0 || maxPlacementFee ? Number(maxPlacementFee) : 100,
              maxOperatingExpense === 0 || maxOperatingExpense ? Number(maxOperatingExpense) : 100,
              maxSetupFee === 0 || maxSetupFee ? Number(maxSetupFee) : 100,
              minExitCharge,
              minHurdleRate,
              minManagementFee,
              minPerformanceFee,
              application?.topupInitiated || false,
              minPlacementFee,
              minOperatingExpense,
              minSetupFee
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, touched, setTouched }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <MFCheckbox
              name={`isAccreditedInvestor`}
              label="Accredited Investor"
              sx={{ mt: 1 }}
              onChange={({ target: { checked } }) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  isAccreditedInvestor: checked,
                });
                setTouched({
                  ...touched,
                  ...removeErrors(application?.topupInitiated || false),
                });
                setSelectedFund(null);
                setSelectedPlan(null);
              }}
              disabled={application?.topupInitiated || isFieldDisabled}
            />
          </Grid>

          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund?.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  isAccreditedInvestor: values.isAccreditedInvestor,
                  totalAmountPayable: 0,
                });
                setTouched({
                  ...touched,
                  ...removeErrors(application?.topupInitiated || false),
                  isAccreditedInvestor: touched.isAccreditedInvestor,
                });
                setSelectedFund(fundsList.find((f) => Number(f?.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled || application?.topupInitiated || false}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
            />
          </Grid>
          <InvestmentDetails
            values={values}
            setSelectedPlan={setSelectedPlan}
            setValues={setValues}
            isFieldDisabled={isFieldDisabled}
            getClassPlanOptionsOrDetails={getClassPlanOptionsOrDetails}
            selectedFund={selectedFund}
            selectedPlan={selectedPlan}
            checkTopUpSection={false}
            ifTopUp={application?.topupInitiated || false}
          />
          {application?.topupInitiated && (
            <>
              <SubHeading>Top Up Section</SubHeading>
              <InvestmentDetails
                values={values}
                setSelectedPlan={setSelectedPlan}
                setValues={setValues}
                isFieldDisabled={isFieldDisabled}
                getClassPlanOptionsOrDetails={getClassPlanOptionsOrDetails}
                selectedFund={selectedFund}
                selectedPlan={selectedPlan}
                checkTopUpSection={true}
                ifTopUp={application?.topupInitiated || false}
              />
            </>
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
