import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { KeyValue } from '../../ApplicationDetails/contributor';
import { SubHeading } from '../../investors/components';
import { Gridstyles } from '../../ApplicationDetails';
import { formatShortDate } from '../../../utils/date';
import { styles } from '../../../Styles/styles';
import { KycApplicant } from '../../../redux-store/types/kyc';

export default function KycPersonalPreview({
  kycApplicants = [],
}: {
  kycApplicants: Partial<KycApplicant>[];
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Personal and Contact Details
              </Typography>
            </Grid>
            {kycApplicants.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Personal Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name'} description={applicant.name} />
                      <KeyValue title={'Pan'} description={applicant.pan} />
                      <KeyValue
                        title={'Date of Birth'}
                        description={formatShortDate(applicant.dob)}
                      />
                      <KeyValue
                        title={'KRA Father/Spouse Name'}
                        description={applicant.cvlKraFatherOrSpouseName}
                      />
                      <KeyValue
                        title={'Father/Spouse Name'}
                        description={applicant.fatherOrSpouseName}
                      />
                      <KeyValue title={'Email Id'} description={applicant.email} />
                      <KeyValue title={'Mobile Number'} description={applicant.phone} />
                    </Grid>
                  </Gridstyles>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Contact Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'KRA Email ID'} description={applicant.cvlKraEmail} />
                      <KeyValue
                        title={'KRA Mobile Number'}
                        description={applicant.cvlKraMobileNumber}
                      />
                      <KeyValue title={'Updated Email ID'} description={applicant.kraEmail} />
                      <KeyValue
                        title={'Updated Mobile Number'}
                        description={applicant.kraMobileNumber}
                      />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
