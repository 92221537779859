import { CommonLayout, CommonLayoutForPOA } from '../../commonComponents';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Box, CardMedia, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { useHistory, useLocation } from 'react-router';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';
import { roleApplicableForKycPreview } from '../../../utils/utilityFunctions';
import KycDocumentPreview from './KycDocumentPreview';
import KycAddressPreview from './KycAddressPreview';
import KycPersonalPreview from './KycPersonalPreview';
import { KycPreviewHeader } from './KycPreviewHeader';
import {
  getKycApplication,
  getKycApplicationDetailsWithRefId,
  getSignedByKycInvestor,
} from '../../../redux-store/actions/kyc';
import { USER_ROLES } from '../../../utils/constant';
import { KycApplicationResponse, SignDetailsType } from '../../../redux-store/types/kyc';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export function ApplicationDetailsCommonLayout({
  loading,
  application,
}: {
  loading: boolean;
  application: KycApplicationResponse;
}): JSX.Element {
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <KycPreviewHeader application={application} />
          <KycPersonalPreview kycApplicants={application?.kyc_applicants} />
          <KycAddressPreview applicants={application?.kyc_applicants} />
          <KycDocumentPreview application={application} />
        </>
      )}
    </>
  );
}

export function KycFrameLayout({ children }: { children: JSX.Element }): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const roleForSidebar = [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMC_ADMIN,
  ].includes(role);
  return !roleForSidebar ? (
    <>
      <CommonLayoutForPOA />
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 10 },
          mt: 10,
          bgcolor: { xs: '', md: 'rgba(238, 244, 251, 0.5)' },
        }}>
        {children}
      </Box>
    </>
  ) : (
    <CommonLayout>{children}</CommonLayout>
  );
}

const KycPreviewButtons = ({
  editKyc,
  referenceId,
  done,
  handleSubmit,
  isSubmitting,
}: {
  editKyc: () => void;
  referenceId: string;
  done: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}): JSX.Element => {
  const kycApplication = useSelector((store: RootStateType) => store.kycApplication);
  const { application = {} } = kycApplication;
  const { editable } = (application || { editable: false }) as KycApplicationResponse;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        '.MuiButton-root': {
          minWidth: { xs: '100px', sm: '150px', md: '200px' },
          maxWidth: '400px',
          mt: 4,
          fontWeight: 600,
          lineHeight: 1.1,
          mx: 1.5,
        },
      }}>
      {editable && (
        <LoadingButton
          loadingPosition="start"
          type="submit"
          variant="outlined"
          sx={{ color: 'primary.main' }}
          onClick={() => editKyc()}>
          Edit KYC
        </LoadingButton>
      )}
      {referenceId && (
        <LoadingButton
          loadingPosition="start"
          type="submit"
          loading={isSubmitting}
          variant="contained"
          sx={{ color: 'common.white' }}
          onClick={handleSubmit}>
          E-Sign
        </LoadingButton>
      )}
      {!referenceId && (
        <LoadingButton
          loadingPosition="start"
          type="submit"
          variant="contained"
          sx={{ color: 'common.white' }}
          onClick={() => done()}>
          Done
        </LoadingButton>
      )}
    </Grid>
  );
};

export default function KycApplicationPreview(): JSX.Element {
  const { id: applicationId = '' } = useParams<{ id: string }>();
  const location = useLocation<{ referenceId: string }>();
  const history = useHistory();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<KycApplicationResponse>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { referenceId = '' } = location?.state || {};

  useEffect(() => {
    (async function () {
      try {
        const _application = referenceId
          ? ((await dispatch(
              getKycApplicationDetailsWithRefId(referenceId)
            )) as unknown as KycApplicationResponse)
          : ((await dispatch(
              getKycApplication(applicationId)
            )) as unknown as KycApplicationResponse);
        setApplication(_application);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (referenceId) {
        const response = (await dispatch(
          getSignedByKycInvestor(referenceId)
        )) as unknown as SignDetailsType;
        window.open(response?.url, '_self');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const editKyc = () => {
    referenceId
      ? history.push(`/investor-kyc/${referenceId}/edit-application/personal-details`, {
          id: application?.id,
          applicant1ReferenceId: referenceId,
        })
      : history.push(`/kyc-application/personal-details`);
  };

  const done = () => {
    history.push('/kyc-applications');
  };

  return roleApplicableForKycPreview(role) ? (
    <KycFrameLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              m: 2,
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!referenceId && (
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  component={RouterLink}
                  to="/kyc-applications">
                  <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
                </IconButton>
              )}
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
                Application Details
              </Typography>
            </Box>
            {application?.editable && (
              <IconButton>
                <CardMedia
                  component="img"
                  src="/images/edit-icon-outlined.svg"
                  alt="Edit Icon"
                  sx={{ width: 'unset' }}
                  onClick={() =>
                    referenceId
                      ? history.push(
                          `/investor-kyc/${referenceId}/edit-application/personal-details`,
                          {
                            id: application?.id,
                            applicant1ReferenceId: referenceId,
                          }
                        )
                      : history.push(`/kyc-application/personal-details`)
                  }
                />
              </IconButton>
            )}
          </Box>
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <ApplicationDetailsCommonLayout
          loading={loading}
          application={application as KycApplicationResponse}
        />
        <KycPreviewButtons
          editKyc={editKyc}
          referenceId={referenceId}
          done={done}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </>
    </KycFrameLayout>
  ) : (
    <></>
  );
}
