import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Applicant, nonIndividualMdmsQuestionsFatca } from '../../redux-store/types/api-types';
import { mdmsCountriesList } from '../../redux-store/types/mdms';
import {
  AssignAnsAndVisibilityForInitialFatca,
  BorderedTextField,
  Group,
  OpenTextField,
  SectionHeader,
  SingleChoiceRadio,
} from '../NonIndividualInvestor/fatca';
import { styles } from '../../Styles/styles';
import { useMdmsCountryData, useMdmsFatcaData } from '../../utils/useDataMdms';

interface Values {
  applicants: {
    fatcadetail: nonIndividualMdmsQuestionsFatca;
  }[];
}

export default function Fatca({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const [fatcaData, setFatcaData] = useState<Values>();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { countries } = useMdmsCountryData();
  const { Fatca } = useMdmsFatcaData();
  useEffect(() => {
    (async function () {
      try {
        setMdmsCountriesList(countries.countries);
        if (Fatca.fatca_questions) {
          const _applicants = {
            applicants: applicants.map((applicant) => {
              const { fatcadetail: existingFatcaDetails = null } = applicant || {};
              return {
                fatcadetail: existingFatcaDetails
                  ? AssignAnsAndVisibilityForInitialFatca(
                      Fatca.fatca_questions,
                      existingFatcaDetails
                    )
                  : [],
              };
            }),
          };
          setFatcaData(_applicants as Values);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [countries, Fatca]);
  return (
    <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
      <Box sx={{ p: 2 }}>
        <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 3 }}>
            Fatca Details
          </Typography>
          <Formik
            initialValues={fatcaData as Values}
            enableReinitialize={true}
            onSubmit={() => {
              console.log('this action should not be trigered');
            }}>
            {({ values }) => (
              <Grid
                container
                rowSpacing={1}
                //columnSpacing={5}
                sx={{
                  width: '100%',
                  ml: 0,
                  '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                }}>
                {values?.applicants.map((applicant, idx) => (
                  <React.Fragment key={idx}>
                    {applicant.fatcadetail.length === 0 && (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Fatca Details
                      </Grid>
                    )}
                    {applicant.fatcadetail.map((item) => (
                      <React.Fragment key={item.id}>
                        {item.isVisible &&
                          item.section_header &&
                          item.question_type !== 'group' && (
                            <SectionHeader value={item.section_header} />
                          )}
                        {item.isVisible && item.question_type === 'single_choice_radio' && (
                          <SingleChoiceRadio
                            data={item}
                            values={applicant.fatcadetail}
                            formValuesIndex={idx}
                            questionId={item.id}
                            disabled={true}
                          />
                        )}
                        {item.isVisible && item.question_type === 'group' && (
                          <Group
                            data={item}
                            formValuesIndex={idx}
                            values={applicant.fatcadetail}
                            _applicants={applicants}
                            disabled={true}
                            nationalityDropdown={mdmsCountriesList}
                          />
                        )}
                        {item.isVisible && item.question_type === 'open_text_field' && (
                          <OpenTextField
                            text={item.question_text}
                            applicantsIndex={idx}
                            questionId={item.id}
                            placeHolder={item?.placeholder || 'Please enter text here'}
                            isMandatory={item.isMandatory}
                            disabled={true}
                          />
                        )}
                        {item.isVisible && item.question_type === 'bordered_text_field' && (
                          <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: '30px' }, pt: 2 }}>
                            <BorderedTextField
                              applicantsIndex={idx}
                              questionId={item.id}
                              label={item.question_text}
                              placeHolder={item?.placeholder || 'Please enter text here'}
                              isMandatory={item.isMandatory}
                              disabled={true}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Formik>
        </Grid>
      </Box>
    </Box>
  );
}
