import { AnyAction } from 'redux';
import {
  GET_JOINTHOLDERLIST_SUCCESS,
  GET_RISKPROFILEMDMS_SUCCESS,
  GET_TOGGLER_SUCCESS,
  nationaliyType,
  togglerType,
} from '../types/mdms';
import { LOGOUT_SUCCESS } from '../types/auth';
import {
  CREATE_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_SUCCESS,
  GET_BROKERS_SUCCESS,
  GET_Documents_SUCCESS,
  GET_Fatca_SUCCESS,
  GET_Ubo_SUCCESS,
} from '../types/application';
import { GET_Countries_SUCCESS } from '../types/onBoarding';
import {
  BrokerList,
  Document,
  FatcaMdms,
  JointholderRelationMaster,
  RiskProfileMaster,
  UboType,
  uboTypes,
} from '../types/api-types';

export type mdmsProp = {
  countries: nationaliyType;
  riskProfile: RiskProfileMaster;
  BrokerList: BrokerList;
  Document: Document;
  Fatca: FatcaMdms;
  Ubo: uboTypes;
  jointHolderRelations: JointholderRelationMaster;
  toggler: togglerType;
};

const initialState: mdmsProp = {
  countries: {
    countries: [],
  },
  riskProfile: { riskProfile: [], profile: [], section: [] },
  BrokerList: {
    broker_list: [],
  },
  Document: {
    individual: [],
    minor: [],
    nominee: [],
    nomineeGuardian: [],
    individual_poa: [],
    nri: [],
    nri_pio: [],
    nri_poa: [],
    nri_poa_pio: [],
    corporate: [],
    trust: [],
    partnership_firm: [],
    huf: [],
    govt_bodies: [],
    registered_society: [],
    fii_fpi: [],
    bank: [],
    institutional_investor: [],
    unincorporated_or_body_of_individuals: [],
    foreign_entity: [],
    corporate_poa: [],
    trust_poa: [],
    partnership_firm_poa: [],
    huf_poa: [],
    govt_bodies_poa: [],
    registered_society_poa: [],
    fii_fpi_poa: [],
    bank_poa: [],
    institutional_investor_poa: [],
    unincorporated_or_body_of_individuals_poa: [],
    foreign_entity_poa: [],
    kycDocs: [],
  },
  Fatca: {
    fatca_questions: [],
  },
  Ubo: {
    uboType: [] as UboType[],
  } as uboTypes,
  jointHolderRelations: {
    singleJointHolder: [],
    twoJointHolders: [],
  },
  toggler: {
    dateFeatureToggles: {
      poaValidationDate: '',
      dematFieldValidationDate: '',
      dpIdFieldValidationDate: '',
    },
  },
};

export const mdmsDataReducer = (state = initialState, action: AnyAction): mdmsProp => {
  switch (action.type) {
    case GET_Countries_SUCCESS:
      return {
        ...state,
        countries: action.body,
      };
    case GET_RISKPROFILEMDMS_SUCCESS:
      return { ...state, riskProfile: action.body };
    case GET_BROKERS_SUCCESS:
      return { ...state, BrokerList: action.body };
    case GET_Documents_SUCCESS:
      return { ...state, Document: action.body };
    case GET_Fatca_SUCCESS:
      return { ...state, Fatca: action.body };
    case GET_Ubo_SUCCESS:
      return { ...state, Ubo: action.body };
    case LOGOUT_SUCCESS:
    case GET_ALL_APPLICATION_SUCCESS:
    case CREATE_APPLICATION_SUCCESS: {
      return { ...initialState };
    }
    case GET_JOINTHOLDERLIST_SUCCESS: {
      return { ...state, jointHolderRelations: action.body };
    }
    case GET_TOGGLER_SUCCESS: {
      return { ...state, toggler: action.body };
    }
    default:
      return state;
  }
};
