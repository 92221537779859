import { ExitToApp } from '@mui/icons-material';
import { AppBar, Divider, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../KycRoutes';
import { Footer } from '../../commonComponents';
import { USER_ROLES } from '../../../utils/constant';
import { logout } from '../../../redux-store/actions/auth';
import { RootStateType } from '../../../redux-store/reducers';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import LoginOtp from '../../auth/loginOtp';
import MobileLogin from '../../auth/MobileLogin';
import KycApplicationPreview from '../preview/ApplicationPreview';
import { useEffect, useState } from 'react';
import { decodeState } from '../../../utils/utilityFunctions';
import { getInvestorTokenFromDigilocker } from '../../../redux-store/actions/kyc';
import LoginRequireRoute from '../../../routes/LoginRequireRoute';
import { LogoEditApplication } from '../../Logo';

export const EditApplication = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <LogoEditApplication />
          {role !== USER_ROLES.INVESTOR && (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  dispatch(logout());
                  history.push('/login');
                }}>
                <ExitToApp fontSize="large" sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <Routes id={id} applicant1ReferenceId={applicant1ReferenceId} />
      </Box>
      <Footer />
    </Box>
  );
};

const KycInvestorRedirectedRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const referenceId = result?.params.slug;

  return (
    <Switch>
      <Route
        exact
        path={path}
        // eslint-disable-next-line
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/login`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/login`} component={MobileLogin} />
      <Route path={`${path}/:referenceId/verify-otp`} component={LoginOtp} />
      <ProtectedRoute
        path={`${path}/:referenceId/kyc-application-details`}
        component={KycApplicationPreview}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investor-kyc/${referenceId}/login`}
        acceptedRoles={[USER_ROLES.INVESTOR]}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/edit-application`}
        component={EditApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/investor-kyc/${referenceId}/login`}
        acceptedRoles={[USER_ROLES.INVESTOR]}
      />
      <LoginRequireRoute
        path="*"
        routePath={referenceId ? `/investor-kyc/${referenceId}/login` : `/login`}
      />
    </Switch>
  );
};

const KycInvestorRoutes = (): JSX.Element => {
  const query = new URLSearchParams(window.location.search);
  const getState = query.get('state');
  const dispatch = useDispatch();
  const [tokenSet, setTokenSet] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async function () {
      if (getState) {
        const { token = '' } = decodeState(getState || '') || { token: '' };
        await dispatch(getInvestorTokenFromDigilocker({ token }));
        setTokenSet(true);
        query.delete('state');
        history.push({
          pathname: location.pathname,
          search: query.toString(),
        });
      }
    })();
  }, []);
  return !tokenSet && getState ? (
    <Typography>Please wait...</Typography>
  ) : (
    <KycInvestorRedirectedRoutes />
  );
};

export default KycInvestorRoutes;
