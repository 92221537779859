import { Box, Button, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { getDocumentTitle } from '../KycDocumentDetails';
import { styles } from '../../../Styles/styles';
import { SubHeading } from '../../investors/components';
import { Item, ItemDetails } from '../../ApplicationDetails';
import DigilockerXmlFile from './DigilockerXmlFile';
import { KycApplicationResponse } from '../../../redux-store/types/kyc';

export default function KycDocumentPreview({
  application,
}: {
  application: KycApplicationResponse;
}): JSX.Element {
  const { kyc_applicants } = application;
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Document Details
            </Typography>
            {kyc_applicants?.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  {applicant.kyc_documents && applicant.kyc_documents.length !== 0 && (
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{'Document Details'}</SubHeading>
                  )}
                  <Grid
                    container
                    pt={applicant.kyc_documents && applicant.kyc_documents.length && 2}
                    pb={applicant.kyc_documents && applicant.kyc_documents.length && 5}
                    columnSpacing={13}
                    sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                    {applicant?.kyc_documents?.length === 0 && !applicant.aadhaar_xml_id && (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Documents
                      </Grid>
                    )}
                    {applicant.kyc_documents &&
                      applicant.kyc_documents?.map((document, index: number) => {
                        return (
                          <React.Fragment key={document.id}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={document.file ? 6 : 12}
                              sx={{ mt: index !== 0 ? 6 : 0 }}>
                              <Item>{getDocumentTitle[document.documentType]}</Item>
                              <ItemDetails>
                                {document.documentName?.toLowerCase() ===
                                getDocumentTitle[document.documentType]?.toLowerCase()
                                  ? document.file?.name
                                  : document.documentName}
                              </ItemDetails>
                            </Grid>
                            {document.file && (
                              <Grid item xs={12} sm={6}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                    flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                                  }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      color: 'common.white',
                                      minWidth: '70%',
                                      fontWeight: 600,
                                      lineHeight: 1.5,
                                    }}
                                    onClick={() => window.open(document.file?.viewUrl)}>
                                    View
                                  </Button>
                                  <Tooltip title={'Download'}>
                                    <IconButton
                                      sx={{
                                        ':hover': {
                                          bgcolor: '#F4FCFC',
                                        },
                                        cursor: 'pointer',
                                        pointerEvents: 'all',
                                      }}
                                      onClick={() => window.open(document.file?.url)}>
                                      <CardMedia
                                        component="img"
                                        src={'/images/download-icon.png'}
                                        alt="Download"
                                        sx={{ width: '25px' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                    <DigilockerXmlFile readOnly={true} />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
