import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { USER_ROLES, updateKycDetails } from '../../utils/constant';

export const KycDetailsLayout = ({
  applicationId,
  applicationNumber = '',
  applicant1ReferenceId = '',
  children,
}: {
  applicationId: string;
  applicationNumber: string;
  applicant1ReferenceId?: string;
  children: JSX.Element;
}): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.kycApplication);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { path } = useRouteMatch();
  const history = useHistory();
  const [transitionClassName, setTransitionClassName] = useState('');
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const { currentStep = null } = application || {};

  const menuItemList = updateKycDetails.map((item) => item.tabText);
  const headerList = updateKycDetails.map((item) => item.header);
  const endPointList = updateKycDetails.map((item) => item.endPoint);

  const activeItem = result ? endPointList.indexOf(result?.params?.slug) : 0;
  const goBack = () => {
    history.push(
      role === USER_ROLES.INVESTOR
        ? `/investor-kyc/${applicant1ReferenceId}/kyc-application-details`
        : '/kyc-applications',
      {
        referenceId: applicant1ReferenceId,
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTransitionClassName('slideInUp');
    const timeoutId = setTimeout(() => {
      setTransitionClassName('');
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeItem]);
  return (
    <>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => goBack()}>
          <ArrowBackIosRoundedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ fontWeight: 500, fontFamily: 'Poppins, sans-serif' }}>Back</Typography>
      </Box>
      {/* Mobile dropdown */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, mx: -2 }}>
        <Button
          variant="text"
          fullWidth
          id="application-button"
          aria-controls="application-dropdown"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<CardMedia component="img" src="/images/person.svg" alt="Icon" />}
          endIcon={<ExpandMoreIcon />}
          sx={{
            color: 'text.primary',
            bgcolor: '#F7FAFD',
            px: 2,
            justifyContent: 'flex-start',
            textAlign: 'left',
            borderBottom: '1px solid #E8E8E8',
            borderRadius: 0,
            '.MuiButton-endIcon': {
              ml: 'auto',
            },
          }}>
          <Box>
            <Typography variant="body2">
              Page {activeItem + 1}/ {application && headerList.length}
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {application && headerList[activeItem]}
            </Typography>
          </Box>
        </Button>
        <Menu
          id="application-dropdown"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'application-button',
          }}
          sx={{
            '.MuiPaper-root ': {
              width: '100%',
              bgcolor: '#F7FAFD',
              '.MuiList-root': {
                p: 0,
                '.MuiDivider-root': { m: 0 },
                '.MuiMenuItem-root': {
                  '.MuiListItemText-primary': {
                    fontWeight: 500,
                    fontSize: 14,
                  },
                  [`:nth-of-type(${activeItem + 1})`]: {
                    '.MuiListItemText-primary': {
                      fontWeight: 600,
                      color: 'primary.main',
                    },
                  },
                },
              },
            },
          }}>
          {menuItemList &&
            menuItemList.map((item, index) => {
              const isItemDisabled = !currentStep ? index !== 0 : index > currentStep;
              return (
                <>
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setAnchorEl(null);
                      history.push(`${endPointList[index]}`, {
                        id: applicationId,
                        applicant1ReferenceId,
                      });
                    }}
                    sx={{ minHeight: '30px' }}
                    disabled={isItemDisabled}>
                    <ListItemText
                      sx={{
                        color: isItemDisabled || currentStep === index ? '#575757' : '#57B6BA',
                        opacity: isItemDisabled ? 0.5 : 1,
                      }}>
                      {item}
                    </ListItemText>
                    <ArrowForwardIcon sx={{ color: '#00D9DA' }} />
                  </MenuItem>
                  <Divider />
                </>
              );
            })}
        </Menu>
      </Box>
      {/* Mobile dropdown End */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '10px 20px',
          mb: 2,
          bgcolor: '#e5f1f8',
          borderRadius: '10px',
          zIndex: 10,
          position: 'sticky',
          top: '63px',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ height: 'fit-content', p: 0 }} onClick={() => goBack()}>
            <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#1C2D47',
            }}>
            {application && ([USER_ROLES.INVESTOR].includes(role) ? 'Edit Kyc' : 'Kyc Onboarding')}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 600, letterSpacing: '1px' }}></Typography>
        {!!applicationNumber && (
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '16px',
              color: '#1C2D47',
              opacity: 0.7,
            }}>
            Application ID: {applicationNumber}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          // py:1,
          bgcolor: 'white',
          boxShadow: { xs: '', md: '0px 4px 12px rgba(0, 0, 0, 0.05)' },
          borderRadius: '10px',
          // height: { xs: 'calc(100vh - 100px)', md: 'calc(100vh - 165px)' },
          // overflowY: 'auto',
        }}>
        <Box>
          <Grid
            container
            sx={
              {
                // height: { xs: '', md: 'calc(100vh - 200px)' },
                // overflowY: 'scroll',
                // '::-webkit-scrollbar': {
                //   display: 'none',
                // },
              }
            }>
            <Grid
              item
              xs={2}
              sx={{
                display: { xs: 'none', md: 'block' },
                backgroundColor: 'rgba(51, 127, 201, 0.025)',
              }}>
              <Stack sx={{ position: 'sticky', top: '108px', py: 4 }}>
                <MenuList>
                  {menuItemList &&
                    menuItemList.map((item, index) => {
                      const isItemDisabled = !currentStep ? index !== 0 : index > currentStep;
                      return (
                        <MenuItem
                          key={index}
                          // component={RouterLink}
                          // to={`${MenuItemListLink[index]}`}
                          onClick={() =>
                            history.push(`${application && endPointList[index]}`, {
                              id: applicationId,
                              applicant1ReferenceId,
                            })
                          }
                          disabled={isItemDisabled}
                          sx={{
                            color: isItemDisabled || currentStep === index ? '#575757' : '#57B6BA',
                            opacity: isItemDisabled ? 0.5 : 1,
                            fontSize: 14,
                            fontWeight: 500,
                            my: 1,
                            whiteSpace: 'break-spaces',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            ':hover': {
                              background:
                                'linear-gradient(88.93deg, rgba(51, 127, 201, 0.0875) 10.58%, rgba(51, 127, 201, 0.025) 93.37%)',
                            },
                            [`:nth-of-type(${activeItem + 1})`]: {
                              fontWeight: 600,
                              color: 'primary.main',
                              background:
                                'linear-gradient(88.93deg, rgba(51, 127, 201, 0.0875) 10.58%, rgba(51, 127, 201, 0.025) 93.37%)',
                              '&:after': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                width: '4px',
                                height: '100%',
                                bgcolor: 'primary.main',
                                borderRadius: '5px',
                              },
                            },
                          }}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </Stack>
            </Grid>
            <Grid item container xs={12} md={10} sx={{ py: 5 }}>
              <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={10}>
                <Box sx={{ px: { xs: 1, md: 'unset' } }} className={transitionClassName}>
                  <Typography
                    component="h3"
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 25,
                      fontWeight: 500,
                      mb: { xs: 2, md: 3.5 },
                      color: application && 'secondary.main',
                    }}>
                    {application && headerList[activeItem]}
                  </Typography>
                  {children}
                </Box>
              </Grid>
              <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
