import { Box, FormHelperText, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendOtp, verifyApplicant, verifyOtp } from '../../redux-store/actions/onBoarding';
import {
  ApplicationProps,
  SendOtpResponseBody,
  VerifyOtpResponseBody,
} from '../../redux-store/types/api-types';
import { addJointHolder, createApplication } from '../../redux-store/actions/application';
import { OtpTimer } from '../commonComponents';
import { Location } from 'history';
import { Formik } from 'formik';
import { MFSubmitButton } from '../../lib/formik';
import { getFirstHolderName } from '../../utils/utilityFunctions';

const intialValues: { OTP: string } = {
  OTP: '',
};

export default function EnterOTP({
  location,
}: {
  location: Location<{
    mobile: string;
    unmaskedphone: string;
    umaskedemailId: string;
    otpVerificationToken: string;
    application: ApplicationProps;
    redirectTo: string;
    panVerificationToken: string;
    countryCode: string;
    email: string;
    countryNameAndCode: string;
    index?: number;
    fromTopUp?: boolean;
  }>;
}): JSX.Element {
  const {
    mobile = '',
    redirectTo = '',
    application,
    email = '',
    index,
    fromTopUp,
  } = location.state || {};
  const { id } = application || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const [{ otpVerificationToken }, setOtpInfo] = useState({
    otpVerificationToken: '',
  });

  const handleSubmit = async (values: { OTP: string }) => {
    const { OTP } = values;
    try {
      if (!OTP || OTP.length !== 6) {
        setError('Enter OTP');
        return;
      }
      const response = (await dispatch(
        verifyOtp({ otpVerificationToken, otp: OTP })
      )) as unknown as VerifyOtpResponseBody;
      const { applicantVerificationToken } = response || {};
      if (id && redirectTo) {
        const res = (await dispatch(
          addJointHolder({ applicantVerificationToken }, id)
        )) as unknown as ApplicationProps;
        history.push(redirectTo, { ...(res || {}) });
        return;
      }
      if (application?.topupInitiated) {
        await dispatch(
          verifyApplicant(application.applicants[index || 0].id || 0, applicantVerificationToken)
        );
        if (index != application.applicants.length - 1) {
          return history.push('enter-pan-number', {
            application: application,
            firstHolderName: getFirstHolderName(application.applicants),
            index: (index || 0) + 1,
            fromTopUp,
          });
        } else {
          return history.push(`/application`, {
            id: application.topupId,
            topUpApplication: application.topupInitiated,
          });
        }
      } else {
        const res = (await dispatch(
          createApplication({ applicantVerificationToken })
        )) as unknown as ApplicationProps;
        history.push('/application', { ...(res || {}) });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { otpVerificationToken } = location.state || {};
    if (!location.state) {
      history.push('/');
    }
    setOtpInfo({ otpVerificationToken });
  }, []);

  const onResend = async () => {
    try {
      const {
        panVerificationToken,
        countryCode,
        countryNameAndCode,
        // eslint-disable-next-line
        email,
        unmaskedphone,
        umaskedemailId,
      } = location.state;
      const res = (await dispatch(
        sendOtp({
          phoneNumber: unmaskedphone,
          emailId: umaskedemailId,
          panVerificationToken,
          countryCode,
          countryNameAndCode,
          bypassOtp: false,
        })
      )) as unknown as SendOtpResponseBody;
      const { otpVerificationToken } = res || {};
      setOtpInfo({ otpVerificationToken });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <Formik initialValues={intialValues} onSubmit={handleSubmit}>
      {({ values, setValues, handleSubmit }) => (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            '.MuiTypography-root': {
              fontSize: 16,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Box sx={{ mb: 3.5 }}>
            <OtpInput
              value={values.OTP}
              onChange={(OTP: string) => setValues({ OTP })}
              numInputs={6}
              hasErrored={!!error}
              isInputNum={true}
              isInputSecure={true}
              shouldAutoFocus={true}
              containerStyle={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                // marginBottom: '30px',
              }}
              inputStyle={{
                padding: '5px 10px',
                width: '45px',
                margin: '2px',
                backgroundColor: 'rgba(206, 229, 237, 0.2)',
                border: 0,
                borderBottom: '1px solid #979797',
                fontSize: '30px',
              }}
              errorStyle={{ borderBottom: '1px solid red', color: 'red' }}
              focusStyle={{ outline: 'none' }}
            />
            {!!error && (
              <FormHelperText error sx={{ mt: 2, textAlign: 'center', fontSize: '14px' }}>
                {error}
              </FormHelperText>
            )}
          </Box>
          <Typography>
            An OTP has been sent to your mobile number
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', color: 'primary.main', textDecoration: 'none' }}>
              {' '}
              {mobile}
            </Typography>
            {email && (
              <Typography component="span" sx={{ color: 'secondary.main' }}>
                {' '}
                and email Id{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold', color: 'primary.main', textDecoration: 'none' }}>
                  {' '}
                  {email}
                </Typography>
              </Typography>
            )}
          </Typography>

          <MFSubmitButton label="Submit" />
          <OtpTimer onResend={onResend} />
        </Box>
      )}
    </Formik>
  );
}

// export function RedirectScreen(): JSX.Element {
//   return (
//     <>
//       <Typography sx={{ fontSize: 24, fontWeight: 'bold', color: 'primary.main' }}>
//         You are now being redirected to Onboard Investor Form
//       </Typography>
//       {/* <MFSubmitButton label="Next" to="/distributor-details" /> */}
//     </>
//   );
// }
