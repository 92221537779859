import * as yup from 'yup';
import { emailRegex, phoneRegExp, stringRegex } from '../../utils/regex';
import { Box, CardMedia, Grid, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import { applicationComparison } from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubHeading } from '../investors/components';
import { updateKycApplication } from '../../redux-store/actions/kyc';
import { DatePicker } from '../../lib/formik/DatePicker';
import { USER_ROLES } from '../../utils/constant';
import { KycApplicationResponse } from '../../redux-store/types/kyc';

const PersonalObject = {
  name: '',
  // namePrefix: '',
  fatherOrSpouseName: '',
  pan: '',
  dob: '',
  kraEmail: '',
  kraMobileNumber: '',
  cvlKraFatherOrSpouseName: '',
  cvlKraEmail: '',
  cvlKraMobileNumber: '',
  email: '',
  phone: '',
};

type PersonalDetailsProps = {
  name: string;
  // namePrefix: string;
  fatherOrSpouseName: string;
  pan: string;
  dob: string;
  kraEmail: string;
  kraMobileNumber: string;
  cvlKraFatherOrSpouseName: string;
  cvlKraEmail: string;
  cvlKraMobileNumber: string;
  email: string;
  phone: string;
};

export type Values = { kyc_applicants: Partial<PersonalDetailsProps>[] };

const initialValues: Values = { kyc_applicants: [PersonalObject] };

export const PersonalDetailsValidation = yup.object().shape({
  kyc_applicants: yup.array().of(
    yup.object().shape({
      fatherOrSpouseName: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Invalid value for father or spouse name'),
      kraEmail: yup.string().nullable().matches(emailRegex, 'Invalid Email ID'),
      kraMobileNumber: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path } = context;
          if (value && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          return true;
        }),
    })
  ),
});

const PersonalDetails = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data,
  index,
}: {
  data: Partial<PersonalDetailsProps>;
  index: number;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.name`}
          label="Full Name"
          placeholder="Enter Full Name"
          // startAdornment={
          //   <InputAdornment
          //     position="start"
          //     sx={{
          //       pr: '0 !important',
          //       mx: '0 !important',
          //       borderRight: '0 !important',
          //     }}>
          //     <MFSelectField
          //       name="namePrefix"
          //       items={salutationsMasters.map((salutation) => ({
          //         key: salutation,
          //         value: salutation,
          //       }))}
          //       disabled={true}
          //       sx={{
          //         color: 'primary.main',
          //         '.MuiSvgIcon-root ': { color: 'primary.main' },
          //         '&.MuiInputBase-root': {
          //           '.MuiSelect-select': {
          //             p: 0,
          //             pr: 2.5,
          //             border: 0,
          //           },
          //         },
          //         '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
          //       }}
          //     />
          //   </InputAdornment>
          // }
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.pan`}
          label="Pan"
          placeholder="Enter Pan"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Date of Birth'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          // maxDate={minDateForContributor()}
          name={`kyc_applicants.${index}.dob`}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.cvlKraFatherOrSpouseName`}
          label="KRA Father/Spouse Name"
          placeholder="Enter KRA Father/Spouse Name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name"
          placeholder="Enter Father/Spouse Name"
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.email`}
          label="Email Id"
          placeholder="Enter Email Id"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.phone`}
          label="Mobile Number"
          placeholder="Enter Mobile Number"
          disabled={true}
        />
      </Grid>
    </>
  );
};

const ContactDetails = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data,
  index,
}: {
  data: Partial<PersonalDetailsProps>;
  index: number;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.cvlKraEmail`}
          label={`KRA Email ID`}
          placeholder="Enter KRA Email ID"
          startAdornment={
            <InputAdornment position="start">
              <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
            </InputAdornment>
          }
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.cvlKraMobileNumber`}
          label="KRA Mobile Number"
          placeholder="Enter KRA Mobile Number"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kraEmail`}
          label="Updated Email ID"
          placeholder="Enter Updated E-Mail ID"
          startAdornment={
            <InputAdornment position="start">
              <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
            </InputAdornment>
          }
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kraMobileNumber`}
          label={`Updated Mobile Number`}
          placeholder="Enter Updated Mobile Number"
          disabled={false}
        />
      </Grid>
    </>
  );
};

export default function KycPersonalDetails(): JSX.Element {
  const kycApplication = useSelector((store: RootStateType) => store.kycApplication);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const location = useLocation<{ applicant1ReferenceId: string }>();
  const { applicant1ReferenceId = '' } = location.state || {};
  const [personalDetails, setPersonalDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = kycApplication;
  const {
    kyc_applicants: existingApplicants = [],
    id: applicationId = '',
    currentStep = null,
  } = application || {};

  useEffect(() => {
    setPersonalDetails({
      ...personalDetails,
      kyc_applicants: existingApplicants.map((applicant) => {
        return {
          name: applicant.name,
          // namePrefix: applicant.namePrefix,
          fatherOrSpouseName: applicant.fatherOrSpouseName,
          pan: applicant.pan,
          dob: applicant.dob,
          kraEmail: applicant.kraEmail,
          kraMobileNumber: applicant.kraMobileNumber,
          cvlKraFatherOrSpouseName: applicant.cvlKraFatherOrSpouseName,
          cvlKraEmail: applicant.cvlKraEmail,
          cvlKraMobileNumber: applicant.cvlKraMobileNumber,
          email: applicant.email,
          phone: applicant.phone,
        };
      }),
    });
  }, [kycApplication]);
  return (
    <Formik
      initialValues={personalDetails}
      onSubmit={async (value) => {
        try {
          const { kyc_applicants = [] } = value;
          const updatedApplicants = existingApplicants.map((applicant, index) => {
            const { kraEmail, kraMobileNumber, fatherOrSpouseName } = kyc_applicants[index];
            return {
              ...applicant,
              kraEmail,
              kraMobileNumber,
              fatherOrSpouseName,
            };
          });

          const checkIfUpdated = applicationComparison(application, {
            ...application,
            kyc_applicants: updatedApplicants,
            currentStep: !currentStep ? 1 : currentStep,
          } as Partial<KycApplicationResponse>);

          if (!checkIfUpdated) {
            setLoading(true);
            (await dispatch(
              updateKycApplication(applicationId, {
                fieldsToUpdate: {
                  fatherOrSpouseName: updatedApplicants[0].fatherOrSpouseName,
                  kraMobileNumber: updatedApplicants[0].kraMobileNumber,
                  kraEmail: updatedApplicants[0].kraEmail,
                },
                applicantId: updatedApplicants[0].id,
                currentStep: 1,
              })
            )) as unknown as KycApplicationResponse;
          }
          if (USER_ROLES.INVESTOR === role || applicant1ReferenceId) {
            history.push(
              `/investor-kyc/${applicant1ReferenceId}/edit-application/address-details`,
              { applicant1ReferenceId }
            );
            return;
          }
          if (
            [
              USER_ROLES.RM,
              USER_ROLES.DISTRIBUTOR,
              USER_ROLES.SUBDISTRIBUTOR,
              USER_ROLES.AMC_ADMIN,
            ].includes(role)
          ) {
            history.push(`/kyc-application/address-details`, { id: application?.id });
            return;
          }
        } catch (error) {
          console.error((error as Error).message);
        } finally {
          setLoading(false);
        }
      }}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, PersonalDetailsValidation, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          onSubmit={handleSubmit}
          noValidate>
          {values.kyc_applicants.map((applicant, index) => {
            return (
              <>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Personal Details
                </SubHeading>
                <PersonalDetails data={applicant} index={index} />
                <SubHeading>Contact details</SubHeading>
                <ContactDetails data={applicant} index={index} />
              </>
            );
          })}
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                mt: 10,
                mb: 2,
                lineHeight: 1.5,
                fontSize: 14,
                px: 15,
                boxSizing: 'border-box',
              }}>
              Proceed
            </LoadingButton>
          </Box>
        </Grid>
      )}
    </Formik>
  );
}
