import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MFTextField, MFSubmitButton } from '../../lib/formik';
import { useDispatch } from 'react-redux';
import { loginWithOtp } from '../../redux-store/actions/auth';
import { useHistory, useRouteMatch } from 'react-router';
import { emailRegex, nonDigitRegex, phoneRegExp } from '../../utils/regex';
import { LoginWithOtpResponseBody } from '../../redux-store/types/api-types';
import { LoginLayout, MobileNumberField } from '../commonComponents';
import { getCountryCodes, preventSpecialCharacters } from '../../utils/utilityFunctions';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Enable_Email_Login, Enable_Mobile_Login } from '../../utils/constant';
import { useEffect, useState } from 'react';
import { getKycInvestorWithReferenceId, getOtpForKycInvestor } from '../../redux-store/actions/kyc';
import { KycApplicant } from '../../redux-store/types/kyc';

interface Values {
  mobile: string;
  countryCode: string;
  countryNameAndCode: string;
  email: string;
}

const validationSchema = yup.object().shape({
  // mobile: yup
  //   .string()
  //   .required('Mobile number is required')
  //   .matches(phoneRegExp, 'Mobile number is not valid')
  //   .length(10, 'Mobile number should be maximum 10 digits'),
  mobile: yup.string().test('mobile', (value, context) => {
    const { createError, path, parent } = context;
    const codesList = getCountryCodes()
      .map((codes) => {
        if (codes.label === parent.countryNameAndCode) {
          return codes.countryCode;
        }
      })
      .filter((ele) => ele)
      .toString();
    if (Enable_Mobile_Login) {
      if (!value) {
        return createError({
          message: 'Mobile number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
    }
    return true;
  }),
  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (Enable_Mobile_Login) {
        if (!value) {
          return createError({
            message: 'Country Code is required',
            path,
          });
        }
        if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
      }
      return true;
    }),
  email: yup
    .string()
    .nullable()
    .test('email', (value, context) => {
      const { createError, path } = context;
      if (Enable_Email_Login) {
        if (!value) {
          return createError({
            message: 'Email ID is required',
            path,
          });
        }
        if (!emailRegex.test(value)) {
          return createError({
            message: 'Invalid Email ID',
            path,
          });
        }
      }
      return true;
    }),
});

const initialValues: Values = {
  mobile: '',
  countryCode: '+91',
  countryNameAndCode: 'India: +91',
  email: '',
};
export default function MobileLogin(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { referenceId } = params as unknown as { referenceId: string };
  const [loginState, setLoginState] = useState<Values>(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: Values) => {
    try {
      // eslint-disable-next-line
      const { mobile = '', countryCode = '+91', countryNameAndCode = 'India: +91', email } = values;
      const getOtpResponse = referenceId
        ? await dispatch(getOtpForKycInvestor(referenceId))
        : ((await dispatch(
            loginWithOtp({
              ...(Enable_Email_Login ? { email: email } : {}),
              ...(Enable_Mobile_Login
                ? { phone: mobile.trim(), countryCode: countryNameAndCode?.split(':')[1].trim() }
                : {}),
            })
          )) as unknown as LoginWithOtpResponseBody);
      const target = referenceId ? `/investor-kyc/${referenceId}/verify-otp` : 'verify-otp';
      history.push(target, {
        mobile,
        countryCode: countryNameAndCode?.split(':')[1].trim(),
        emailDecrypted: email,
        ...(getOtpResponse || {}),
      });
    } catch (e) {
      console.error((e as unknown as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    !!referenceId &&
      (async function () {
        try {
          setIsLoading(true);
          const response = (await dispatch(
            getKycInvestorWithReferenceId(referenceId)
          )) as unknown as Partial<KycApplicant>;
          if (!isComponentAlive) return;
          setLoginState({
            ...loginState,
            mobile: response.phone || '',
            email: response.email || '',
          });
          setIsLoading(false);
        } catch (e) {
          console.error((e as Error).message);
        }
      })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  return (
    <LoginLayout LoginText="Login">
      <Formik
        onSubmit={onSubmit}
        initialValues={loginState}
        validationSchema={validationSchema}
        enableReinitialize={true}>
        {({ handleSubmit, values }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              '.MuiLoadingButton-root': {
                mt: 5,
              },
              '.MuiInputLabel-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 16,
                fontWeight: 500,
              },
            }}>
            {Enable_Mobile_Login && (
              <MobileNumberField
                label="Mobile Number"
                name="mobile"
                placeholder="Enter Mobile number"
                onKeyDown={(e) => {
                  preventSpecialCharacters(e);
                }}
                countryCodeFieldName={'countryNameAndCode'}
                countryCodeValue={values.countryNameAndCode}
                {...(Enable_Mobile_Login ? { autoFocus: true } : {})}
                regexForFilterValue={nonDigitRegex}
                disabled={!!referenceId}
              />
            )}
            {Enable_Email_Login && (
              <MFTextField
                autoComplete="off"
                // autoFocus
                label="Email ID"
                name="email"
                placeholder="Enter Email ID"
                {...(!Enable_Mobile_Login ? { autoFocus: true } : {})}
                required
                disabled={!!referenceId}
              />
            )}

            <MFSubmitButton label="Get OTP" disabled={isLoading} />
          </Box>
        )}
      </Formik>
    </LoginLayout>
  );
}
