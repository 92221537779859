import React from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  RadioGroup,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { KYC_APPLICATION_STATUS } from '../../../utils/constant';
import { FiltersForApplicationStyles } from '../../../Styles/styles';
import { RootStateType } from '../../../redux-store/reducers';
import { KycApplicationFilterType } from '../../../redux-store/types/kyc';

type FiltersDropDownProps = {
  filters: KycApplicationFilterType;
  anchorEl: boolean;
  onFiltersChange: (value: KycApplicationFilterType) => void;
  handleClose: () => void;
};

const AccordionFilter = ({
  filterName = '',
  children,
  expanded,
  handleChange,
}: {
  filterName: string;
  children: JSX.Element;
  expanded: boolean;
  handleChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
    filterName: string
  ) => void;
}): JSX.Element => {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          boxShadow: 'none',
        },
      }}
      expanded={expanded}
      onChange={(event, expanded) => handleChange(event, expanded, filterName)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <FormLabel component="legend">{filterName}</FormLabel>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

// eslint-disable-next-line
const Inival = (role: string) => {
  return {
    status: [],
  };
};

export const KycInitialFilters = (role: string, storeStatus: string): KycApplicationFilterType => {
  return {
    status: storeStatus ? storeStatus.split(',') : [],
  };
};

export const KycFilterDropdown = ({
  filters,
  anchorEl,
  onFiltersChange,
  handleClose,
}: FiltersDropDownProps): JSX.Element => {
  const {
    // eslint-disable-next-line
    page,
    status: storeStatus,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [filterExpanded, setExpanded] = useState<boolean | string>(false);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [modalState, setModalState] = useState(KycInitialFilters(role, storeStatus));

  useEffect(() => {
    setModalState(filters);
  }, [filters, anchorEl]);

  const _handleModalState = () => {
    handleClose();
    setModalState(KycInitialFilters(role, storeStatus));
  };

  const _handleApplyFilters = () => {
    onFiltersChange(modalState);
    _handleModalState();
  };

  const kycApplicationStatusList = Object.keys(KYC_APPLICATION_STATUS).map((status) => ({
    key: status,
    value: KYC_APPLICATION_STATUS[status],
  }));

  const _handleClearFilters = () => {
    onFiltersChange(Inival(role));
    handleClose();
  };

  // eslint-disable-next-line
  const _handleSelection = (item: string[] | string | number, key: string | any) => {
    const index = (modalState[key] as string[]).indexOf(item as string);
    if (index === -1) {
      setModalState({
        ...modalState,
        [key]: [...((modalState[key] as string[]) || []), item] as string[],
      });
    } else {
      setModalState({
        ...modalState,
        // eslint-disable-next-line
        [key]: (modalState[key] as string[]).filter((status: any) => status != (item as string)),
      });
    }
  };

  const handleAccordionExpanded = (
    event: React.SyntheticEvent<Element, Event>,
    isExpanded: boolean,
    panel: string
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Drawer anchor={'right'} open={anchorEl} onClose={_handleModalState}>
        <FormControl component="fieldset" sx={FiltersForApplicationStyles}>
          <Box>
            <IconButton sx={{ float: 'right', p: 0 }} onClick={_handleModalState}>
              <CancelOutlinedIcon sx={{ color: '#EA5167' }} fontSize="large" />
            </IconButton>
          </Box>
          <Box>
            <AccordionFilter
              filterName={'Status'}
              expanded={filterExpanded === 'Status'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="status" defaultValue="" name="status">
                {kycApplicationStatusList.map((type: { key: string; value: string }) => (
                  <FormControlLabel
                    key={type.key}
                    value={type.key}
                    onChange={() => _handleSelection(type.key, 'status')}
                    control={
                      <Checkbox size="small" checked={modalState.status.includes(type.key)} />
                    }
                    label={type.value}
                    title={type.value}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
              mt: 2,
              '& .MuiButton-root': {
                fontSize: 16,
                mx: 1,
                px: 3.5,
                py: 0.5,
              },
            }}>
            <Button
              type="submit"
              sx={{
                color: 'common.white',
                '&, :hover': { bgcolor: 'primary.main' },
              }}
              onClick={_handleApplyFilters}>
              Apply Filters
            </Button>
            <Button
              type="reset"
              sx={{
                color: 'text.primary',
                border: '1px solid #1F4173',
                '&, :hover': { bgcolor: 'common.white' },
              }}
              onClick={_handleClearFilters}>
              Clear Filters
            </Button>
          </Box>
        </FormControl>
      </Drawer>
    </Box>
  );
};
