import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
  Radio,
} from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useEffect, useState } from 'react';
import {
  getAddressFields,
  getAddressData,
  applicationComparison,
} from '../../utils/utilityFunctions';
import { ConfirmationDialog } from '../commonComponents';
import LoadingButton from '@mui/lab/LoadingButton';
import { RootStateType } from '../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicantAddressType } from '../../redux-store/types/api-types';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import { useHistory, useLocation } from 'react-router';
import * as yup from 'yup';
import { showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { Notes, ProceedSaveLater, SubHeading } from '../investors/components';
import {
  getAddressFetchedFromDigilocker,
  getDigilockerUrl,
  setDefaultAddresses,
  updateKycApplication,
} from '../../redux-store/actions/kyc';
import { MFTextField } from '../../lib/formik';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { USER_ROLES } from '../../utils/constant';
import {
  GetDigilockerUrlResponse,
  KycApplicant,
  KycApplicationResponse,
  updatedDigilockerAddress,
} from '../../redux-store/types/kyc';

export interface AddressPopupValues {
  selected: string;
}

const UpdateAdressPopupInitialValues: AddressPopupValues = {
  selected: '',
};

const AddressObject = {
  kyc_applicants: [
    {
      kyc_addresses: {
        permanent: getAddressFields('permanent'),
        correspondence: getAddressFields('correspondence'),
      },
    },
  ],
  countryDropdown: [],
  statesDropdown: [],
};

type AddressType = {
  permanent?: Partial<ApplicantAddressType>;
  correspondence?: Partial<ApplicantAddressType>;
  overseas?: Partial<ApplicantAddressType>;
  [key: string]: Partial<ApplicantAddressType> | string | null | undefined;
};

type AddressDetailsProps = {
  kyc_addresses: AddressType;
};

export type Values = {
  kyc_applicants: AddressDetailsProps[];
  countryDropdown: string[];
  statesDropdown: string[];
};

export const updateAddressPopupValidation = yup.object().shape({
  selected: yup.string().nullable().required('Atleast One Option is required'),
});

const checkAddressIsFetchedFromKRA = (kycApplicant: KycApplicant, addressType: string) => {
  return kycApplicant.kyc_addresses.find(
    (_address) => _address.address_type?.toLocaleLowerCase() === addressType?.toLocaleLowerCase()
  )?.fetchedFromKRA;
};

const checkAddressIsFetchedFromDigilocker = (kycApplicant: KycApplicant, addressType: string) => {
  return kycApplicant.kyc_addresses.find(
    (_address) => _address.address_type?.toLocaleLowerCase() === addressType?.toLocaleLowerCase()
  )?.fetchedFromDigiLocker;
};

export const Address = ({
  addressType = '',
  index,
  nationalitiesMdmsResponse,
}: {
  addressType: string;
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
}): JSX.Element => {
  const isFieldDisabled = true;
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.address1`}
          label={`Address Line 1`}
          placeholder="Enter Address Line 1"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.address2`}
          label={`Address Line 2`}
          placeholder="Enter Address Line 2"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.pincode`}
          label={`Pincode`}
          placeholder="Enter Pincode"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.city`}
          label={`City`}
          placeholder="Enter City"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.district`}
          label={`District`}
          placeholder="Enter District"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.state`}
          label={`State`}
          placeholder="Enter State"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`kyc_applicants.${index}.kyc_addresses.${addressType}.country`}
          label="Country"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          disabled={isFieldDisabled}
          searchFieldPlaceholder={'Search Country'}
        />
      </Grid>
    </>
  );
};

export default function KycAddressDetails(): JSX.Element {
  const initialValues: Values = AddressObject;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ applicant1ReferenceId: string }>();
  const { applicant1ReferenceId = '' } = location.state || {};
  const [openDialog, setDialog] = useState(false);
  const [openConfirmDialog, setConfirmDialog] = useState(false);
  const [openDefaultDialog, setDefaultDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.kycApplication);
  const { token, role = '' } = useSelector((store: RootStateType) => store.auth);
  const { token: kycToken = '' } = useSelector((store: RootStateType) => store.kycInvestor);
  const [addressDetails, setAddressDetails] = useState(initialValues);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  // const [mdmsStatesList, setMdmsStatesList] = useState<mdmsStatesList[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(UpdateAdressPopupInitialValues);
  const [consentDialogLoading, setConsentDialogLoading] = useState(false);
  const [addressSaved, setAddressSaved] = useState(false);
  const [saveType, setSaveType] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(window.location.search);
  const code = query.get('statusCode');
  const {
    kyc_applicants: existingApplicants = [] as KycApplicant[],
    id: applicationId = '',
    currentStep = null,
  } = application || {};

  useEffect(() => {
    (async function () {
      try {
        if (application) {
          const nationalitiesMdmsMasters = (await dispatch(
            getNationalityList()
          )) as unknown as nationaliyType;
          setMdmsCountriesList(nationalitiesMdmsMasters.countries);
          // const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
          // setMdmsStatesList(statesMdmsMasters.states);
          const correspondence = getAddressData(
            'correspondence',
            existingApplicants[0]?.kyc_addresses
          );
          const permanent = getAddressData('permanent', existingApplicants[0]?.kyc_addresses);
          correspondence.country = correspondence.country?.toUpperCase() || 'INDIA';
          permanent.country = permanent.country?.toUpperCase();
          correspondence.state =
            correspondence.country?.toUpperCase() === 'INDIA'
              ? correspondence.state?.toUpperCase()
              : correspondence.state;
          permanent.state =
            permanent.country?.toUpperCase() === 'INDIA'
              ? permanent.state?.toUpperCase()
              : permanent.state;
          // const overseas = getAddressData('overseas', referenceDetail.kyc_addresses);
          setAddressDetails({
            ...addressDetails,
            kyc_applicants: addressDetails.kyc_applicants?.map((item, index) => {
              return {
                ...existingApplicants[index],
                kyc_addresses: {
                  correspondence: correspondence,
                  permanent: permanent,
                },
              };
            }),
            // statesDropdown: statesMdmsMasters.states.map((list) => list.name),
            countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
          });
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  useEffect(() => {
    try {
      if (code === 'access_allowed') {
        enqueueSnackbar('Address Fetched successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setDialog(true);
      }
      if (code === 'access_denied') {
        throw 'Fetching Address from Digilocker failed';
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  }, []);

  async function onUpdateClick() {
    try {
      if (code === 'access_allowed') {
        setDialog(true);
      } else {
        setUpdateLoading(true);
        if (!existingApplicants[0]?.aadhaar_xml_id) {
          const response = (await dispatch(
            getDigilockerUrl(existingApplicants[0].referenceId, token || kycToken, role)
          )) as unknown as GetDigilockerUrlResponse;
          const paramsString = response?.uri;
          if (paramsString) {
            const searchParams = new URLSearchParams(paramsString);
            if (searchParams.get('state') === null) {
              history.push('/not-authorized', {
                role: 'investor',
              });
            } else {
              window.open(response.uri, '_blank');
            }
          }
        } else {
          setDialog(true);
        }
      }
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setUpdateLoading(false);
    }
  }

  function addressPageNavigation() {
    if (USER_ROLES.INVESTOR === role || applicant1ReferenceId) {
      history.push(`/investor-kyc/${applicant1ReferenceId}/edit-application/document-details`, {
        applicant1ReferenceId,
      });
      return;
    }
    if (
      [
        USER_ROLES.RM,
        USER_ROLES.DISTRIBUTOR,
        USER_ROLES.SUBDISTRIBUTOR,
        USER_ROLES.AMC_ADMIN,
      ].includes(role)
    ) {
      history.push(`/kyc-application/document-details`, { id: application?.id });
      return;
    }
  }

  return (
    <>
      <Formik
        initialValues={addressDetails}
        onSubmit={async () => {
          if (selectedCheckbox.selected) {
            const updatedApplicants = existingApplicants.map((applicant: KycApplicant) => {
              return {
                ...applicant,
                updateCorrespondenceAddressToCVL: ['both', 'correspondence'].includes(
                  selectedCheckbox.selected
                ),
                updatePermanentAddressToCVL: ['both', 'permanent'].includes(
                  selectedCheckbox.selected
                ),
              };
            });

            const checkIfUpdated = applicationComparison(application, {
              ...application,
              kyc_applicants: updatedApplicants,
              currentStep: !currentStep ? 1 : currentStep,
            } as Partial<KycApplicationResponse>);
            if (!checkIfUpdated) {
              setConfirmDialog(true);
            } else if (
              existingApplicants[0].kyc_addresses.some((address) => !!address.fetchedFromKRA)
            ) {
              (await dispatch(
                updateKycApplication(applicationId, {
                  applicantId: existingApplicants[0].id,
                  currentStep: 2,
                })
              )) as unknown as KycApplicationResponse;
              addressPageNavigation();
            }
          } else {
            (await dispatch(
              updateKycApplication(applicationId, {
                applicantId: existingApplicants[0].id,
                currentStep: 2,
              })
            )) as unknown as KycApplicationResponse;
            addressPageNavigation();
          }
        }}
        enableReinitialize={true}>
        {({ handleSubmit, values }) => (
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            onSubmit={handleSubmit}
            noValidate>
            {existingApplicants[0]?.kyc_addresses &&
            existingApplicants[0]?.kyc_addresses.length > 0 ? (
              <>
                {(checkAddressIsFetchedFromKRA(existingApplicants[0], 'correspondence') ||
                  checkAddressIsFetchedFromKRA(existingApplicants[0], 'permanent') ||
                  checkAddressIsFetchedFromDigilocker(existingApplicants[0], 'correspondence') ||
                  checkAddressIsFetchedFromDigilocker(existingApplicants[0], 'permanent')) && (
                  <>
                    <Notes
                      displayContent={
                        'Click  below on Update To get updated Address from Digilocker'
                      }
                    />
                    <Box sx={{ textAlign: 'right', width: '100%' }}>
                      <LoadingButton
                        loadingPosition="start"
                        onClick={onUpdateClick}
                        loading={updateLoading}
                        disabled={
                          dialogLoading ||
                          consentDialogLoading ||
                          !!values.kyc_applicants[0].kyc_addresses.correspondence
                            ?.fetchedFromDigiLocker ||
                          !!values.kyc_applicants[0].kyc_addresses.permanent?.fetchedFromDigiLocker
                        }
                        variant="contained"
                        sx={{
                          mt: 4,
                          mb: 2,
                          lineHeight: 1.5,
                          fontSize: 14,
                          px: 5,
                          boxSizing: 'border-box',
                        }}>
                        Update
                      </LoadingButton>
                    </Box>
                  </>
                )}
                {(checkAddressIsFetchedFromKRA(existingApplicants[0], 'correspondence') ||
                  checkAddressIsFetchedFromDigilocker(existingApplicants[0], 'correspondence')) && (
                  <>
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Correspondence Address
                    </SubHeading>
                    <Address
                      addressType={'correspondence'}
                      index={0}
                      nationalitiesMdmsResponse={mdmsCountriesList}
                    />
                  </>
                )}
                {(checkAddressIsFetchedFromKRA(existingApplicants[0], 'permanent') ||
                  checkAddressIsFetchedFromDigilocker(existingApplicants[0], 'permanent')) && (
                  <>
                    <SubHeading>Permanent Address</SubHeading>
                    <Address
                      addressType={'permanent'}
                      index={0}
                      nationalitiesMdmsResponse={mdmsCountriesList}
                    />
                  </>
                )}
                <ProceedSaveLater
                  saveForLaterButtonText="Set To Default"
                  saveButtonText="Proceed"
                  saveAndProceed={handleSubmit}
                  saveLater={() => setDefaultDialog(true)}
                  disabled={
                    (code === 'access_allowed' && !addressSaved) ||
                    updateLoading ||
                    consentDialogLoading
                  }
                  showSaveForLater={
                    existingApplicants[0].kyc_addresses?.some(
                      (e: Partial<ApplicantAddressType>) => e?.fetchedFromDigiLocker
                    ) || addressSaved
                  }
                  showEndIcon={false}
                  loader={dialogLoading}
                  clickedButton={saveType}
                  saveForLaterType={'button'}
                />
              </>
            ) : (
              <Notes displayContent={'No data available please try again'} />
            )}
          </Grid>
        )}
      </Formik>
      <ConfirmationDialog
        message="The KYC details have been changed and a CVL KYC form will be generated for e-signing as part of KYC Modification Request."
        open={openConfirmDialog}
        setOpen={() => setConfirmDialog(false)}
        onSave={async () => {
          try {
            setDialogLoading(true);
            setConfirmDialog(false);
            setSaveType('save and proceed');
            (await dispatch(
              updateKycApplication(applicationId, {
                updateCorrespondenceAddressToCVL: ['both', 'correspondence'].includes(
                  selectedCheckbox.selected
                ),
                updatePermanentAddressToCVL: ['both', 'permanent'].includes(
                  selectedCheckbox.selected
                ),
                applicantId: existingApplicants[0].id,
                currentStep: 2,
              })
            )) as unknown as KycApplicationResponse;
            setDialogLoading(false);
            addressPageNavigation();
          } catch (error) {
            setDialogLoading(false);
            console.error((error as Error).message);
          }
        }}
        // disabled={dialogLoading}
        onCancel={() => setConfirmDialog(false)}
      />
      <ConfirmationDialog
        message="Are You Sure You Want to Revert to the Default Kyc Details? Any Update Change will be lost."
        open={openDefaultDialog}
        setOpen={() => setDefaultDialog(false)}
        onSave={async () => {
          try {
            setDialogLoading(true);
            setDefaultDialog(false);
            setSaveType('save for later');
            const defaultAddressResponse = (await dispatch(
              setDefaultAddresses(existingApplicants[0].id)
            )) as unknown as KycApplicationResponse;
            (await dispatch(
              updateKycApplication(defaultAddressResponse?.id, {
                applicantId: defaultAddressResponse?.kyc_applicants[0]?.id || '',
                currentStep: 2,
              })
            )) as unknown as KycApplicationResponse;
            addressPageNavigation();
          } catch (error) {
            console.error((error as Error).message);
          } finally {
            setDialogLoading(false);
          }
        }}
        onCancel={() => setDefaultDialog(false)}
      />
      <Dialog open={openDialog} onClose={() => () => setDialog(false)}>
        <Box
          sx={{
            p: { xs: 2, sm: '45px 40px' },
            borderRadius: '10px',
            // width: { xs: '70%', sm: '100%' },
            maxWidth: 410,
            height: { xs: '90%', md: 'unset' },
            overflowY: 'auto',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiButton-root': {
                minWidth: 100,
                fontSize: 16,
              },
            }}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: '#337FC9',
                textAlign: 'center',
                mb: 4,
              }}>
              {`Do you wish to update ${
                existingApplicants[0]?.kyc_addresses?.find(
                  (_address: Partial<ApplicantAddressType>) =>
                    _address.address_type === 'correspondence'
                )
                  ? ''
                  : 'Permanent Address'
              }?`}
            </Typography>
            <Formik
              initialValues={selectedCheckbox}
              onSubmit={async (value) => {
                try {
                  const corresspondenceAddressCheck = existingApplicants[0]?.kyc_addresses?.find(
                    (_address: Partial<ApplicantAddressType>) =>
                      _address.address_type === 'correspondence'
                  );
                  setConsentDialogLoading(true);
                  const response = (await dispatch(
                    getAddressFetchedFromDigilocker(
                      existingApplicants[0].id,
                      corresspondenceAddressCheck ? value.selected : 'permanent'
                    )
                  )) as unknown as updatedDigilockerAddress;
                  const { correspondenceAddress = {}, permanentAddress } = response;
                  if (
                    existingApplicants[0]?.kyc_addresses?.find(
                      (_address: Partial<ApplicantAddressType>) =>
                        _address.address_type === 'correspondence'
                    )
                  ) {
                    correspondenceAddress.country = correspondenceAddress.country?.toUpperCase();
                    correspondenceAddress.state =
                      correspondenceAddress.country?.toUpperCase() === 'INDIA'
                        ? correspondenceAddress.state?.toUpperCase()
                        : correspondenceAddress.state;
                  }
                  permanentAddress.country = permanentAddress.country?.toUpperCase();
                  permanentAddress.state =
                    permanentAddress.country?.toUpperCase() === 'INDIA'
                      ? permanentAddress.state?.toUpperCase()
                      : permanentAddress.state;
                  setAddressDetails({
                    ...addressDetails,
                    kyc_applicants: addressDetails.kyc_applicants.map((item) => {
                      return {
                        ...item,
                        kyc_addresses: {
                          ...(existingApplicants[0]?.kyc_addresses?.find(
                            (_address: Partial<ApplicantAddressType>) =>
                              _address.address_type === 'correspondence'
                          ) && { correspondence: correspondenceAddress }),
                          // response.correspondenceAddress,
                          permanent: permanentAddress,
                        },
                      };
                    }),
                  });
                  if (!addressSaved) {
                    setAddressSaved(true);
                  }
                  setSelectedCheckbox({
                    selected: corresspondenceAddressCheck ? value.selected : 'permanent',
                  });
                } catch (error) {
                  console.error((error as Error).message);
                } finally {
                  setConsentDialogLoading(false);
                  setDialog(false);
                }
              }}
              validate={(values: AddressPopupValues) => {
                try {
                  existingApplicants[0]?.kyc_addresses?.find(
                    (_address: Partial<ApplicantAddressType>) =>
                      _address.address_type === 'correspondence'
                  ) && validateYupSchema(values, updateAddressPopupValidation, true, values);
                } catch (e) {
                  return yupToFormErrors(e);
                }
              }}
              enableReinitialize={true}>
              {({ handleSubmit, values, setFieldValue, errors }) => (
                <>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      fontSize: 14,
                      textAlign: 'center',
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                      '.MuiTypography-root': {
                        fontSize: 15,
                        fontWeight: 500,
                      },
                    }}>
                    {existingApplicants[0]?.kyc_addresses?.find(
                      (_address: Partial<ApplicantAddressType>) =>
                        _address.address_type === 'correspondence'
                    ) && (
                      <RadioGroup aria-label="modeOfHolding" defaultValue="">
                        <FormControlLabel
                          key="correspondence"
                          value="correspondence"
                          onChange={() =>
                            setFieldValue(
                              'selected',
                              values.selected === 'correspondence' ? '' : 'correspondence'
                            )
                          }
                          control={
                            <Radio size="small" checked={values.selected === 'correspondence'} />
                          }
                          label="Correspondence Address"
                          title="Correspondence Address"
                        />
                        <FormControlLabel
                          key="permanent"
                          value="permanent"
                          onChange={() =>
                            setFieldValue(
                              'selected',
                              values.selected === 'permanent' ? '' : 'permanent'
                            )
                          }
                          control={<Radio size="small" checked={values.selected === 'permanent'} />}
                          label="Permanent Address"
                          title="Permanent Address"
                        />
                        <FormControlLabel
                          key="both"
                          value="both"
                          onChange={() =>
                            setFieldValue('selected', values.selected === 'both' ? '' : 'both')
                          }
                          control={<Radio size="small" checked={values.selected === 'both'} />}
                          label="Both"
                          title="Both"
                        />
                      </RadioGroup>
                    )}
                    {Object.keys(errors).includes('selected') ? (
                      <FormHelperText
                        error
                        sx={{ mt: 2, mb: 2, textAlign: 'center', fontSize: '14px' }}>
                        {errors.selected}
                      </FormHelperText>
                    ) : null}
                    <Button
                      variant="contained"
                      disabled={consentDialogLoading}
                      type="submit"
                      sx={{ color: 'common.white', mr: 2, mt: 1 }}>
                      {consentDialogLoading ? (
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        'Yes'
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setDialog(false)}
                      disabled={consentDialogLoading}
                      sx={{ color: 'primary.main', mt: 1 }}>
                      No
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
