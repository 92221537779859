import { Box, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { CommonLayout } from '../commonComponents';
import { Location } from 'history';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { styles } from '../../Styles/styles';
import InitiateKyc from './InitiateKyc';

function Routes(): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/create`} from={location.pathname} />}
        />
        <Route path={`${path}/create`} component={InitiateKyc} />
      </Switch>
    </Router>
  );
}

export default function KycOnboardingRoutes({
  location,
}: {
  location: Location<{ application: ApplicationProps; firstHolderName: string }>;
}): JSX.Element {
  const { application } = location.state || {};
  const { id } = application || {};

  function OnBoarding(): JSX.Element {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton
            sx={{ height: 'fit-content', p: 0 }}
            component={RouterLink}
            to={'/kyc-applications'}>
            <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
          </IconButton>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
            {'Initiate KYC'}
          </Typography>
        </Box>
        <Box sx={styles.ApplicationDetailsCommonLayout}>
          <Box
            sx={{
              p: 2,
            }}>
            <Grid container sx={{ mt: id ? 1 : 5 }}>
              <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <CardMedia component="img" src="/images/onboard-investor.svg" alt="Illustration" />
              </Grid>
              <Grid item container xs={12} md={6} sx={{ alignSelf: 'center' }}>
                <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
                <Grid item xs={12} sm={8}>
                  <Routes />
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <CommonLayout>
      <OnBoarding />
    </CommonLayout>
  );
}
