import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { getXmlFile } from '../../../redux-store/actions/kyc';

const DigilockerXmlFile = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.kycApplication);
  const { kyc_applicants = [], id: applicationId = '' } = application || {};
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  async function downloadDigilockerXml() {
    try {
      setDownloading(true);
      const res = (await dispatch(getXmlFile(applicationId))) as unknown as { url: string };
      window.open(res?.url, '_blank');
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setDownloading(false);
    }
  }
  return (
    <React.Fragment>
      {kyc_applicants[0].aadhaar_xml_id ? (
        <React.Fragment>
          <Grid
            item
            xs={12}
            sm={6}
            md={document ? 6 : 12}
            sx={{
              mt: 6,
              display: 'flex',
              alignItems: 'center',
              color: readOnly ? 'rgba(0, 0, 0, 0.6)' : 'primary.main',
            }}>
            <Typography sx={{ fontWeight: 500, fontSize: 15 }}>{'Digilocker XML File'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: readOnly ? '70%' : '100%',
                mt: { sm: 8, xs: 2 },
                fontWeight: 600,
                lineHeight: 1.5,
              }}
              disabled={downloading}
              onClick={downloadDigilockerXml}>
              Download
            </Button>
          </Grid>
        </React.Fragment>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DigilockerXmlFile;
