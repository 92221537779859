import { CommonLayout } from '../commonComponents';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Router,
  useParams,
} from 'react-router';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';
import { USER_ROLES } from '../../utils/constant';
import KycPersonalDetails from './KycPersonalDetails';
import KycAddressDetails from './KycAddressDetails';
import KycDocumentDetails from './KycDocumentDetails';
import {
  getKycApplication,
  getKycApplicationDetailsWithRefId,
} from '../../redux-store/actions/kyc';
import { KycDetailsLayout } from './KycDetailsLayout';
import { decodeState } from '../../utils/utilityFunctions';
import { KycApplicationResponse } from '../../redux-store/types/kyc';

export const Routes = ({
  id,
  applicant1ReferenceId,
}: {
  id: string;
  applicant1ReferenceId?: string;
  applicationType?: string;
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const {
    auth: { role },
    kycApplication: { application: _application },
    kycInvestor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { applicationNumber } = _application || {};

  const query = new URLSearchParams(window.location.search);
  const stateFromDigi = query.get('state') || '';
  const { esignReferenceId = '', role: roleFromDigi = '' } = decodeState(stateFromDigi || '') || {
    esignReferenceId: '',
    role: '',
  };
  const { referenceId: referenceIdFromDigi = '' } = useParams<{ referenceId: string }>();

  useEffect(() => {
    async function fetchApplication() {
      try {
        if (role === USER_ROLES.INVESTOR && !token) {
          history.push(`/investor-kyc/${applicant1ReferenceId}/login`);
          return;
        }
        if (
          [USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(
            roleFromDigi as USER_ROLES
          )
        ) {
          esignReferenceId && (await dispatch(getKycApplicationDetailsWithRefId(esignReferenceId)));
          return;
        }
        if (USER_ROLES.INVESTOR === roleFromDigi) {
          referenceIdFromDigi &&
            (await dispatch(getKycApplicationDetailsWithRefId(referenceIdFromDigi)));
          return;
        }
        if (id) {
          (await dispatch(getKycApplication(id))) as unknown as KycApplicationResponse;
          return;
        }
        // else {
        //   history.push('/kyc-applications');
        //   console.error('No application id found');
        // }
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    fetchApplication();
  }, []);

  return (
    <KycDetailsLayout
      applicationId={id}
      applicant1ReferenceId={applicant1ReferenceId}
      applicationNumber={applicationNumber || ''}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: `${path}/personal-details`,
                  state: { id, applicant1ReferenceId },
                }}
                from={location.pathname}
              />
            )}
          />
          <Route path={`${path}/personal-details`} component={KycPersonalDetails} />
          <Route path={`${path}/address-details`} component={KycAddressDetails} />
          <Route path={`${path}/document-details`} component={KycDocumentDetails} />
        </Switch>
      </Router>
    </KycDetailsLayout>
  );
};

const KYCRoutes = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string; applicationType?: string }>;
}): JSX.Element => {
  const { id = '', applicant1ReferenceId = '', applicationType = '' } = location.state || {};
  return (
    <CommonLayout>
      <Routes
        id={id}
        applicant1ReferenceId={applicant1ReferenceId}
        applicationType={applicationType}
      />
    </CommonLayout>
  );
};

export default KYCRoutes;
