import { Box } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  emailRegex,
  individualPanRegex,
  nonDigitRegex,
  nonIndividualPanRegex,
  phoneRegExp,
} from '../../utils/regex';
import {
  futureAge,
  getCountryCodes,
  isMinor,
  maxAge,
  preventSpecialCharacters,
} from '../../utils/utilityFunctions';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { MobileNumberField } from '../commonComponents';
import { useSnackbar } from 'notistack';
import { TextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { createKycApplication } from '../../redux-store/actions/kyc';
import { KycApplicationResponse } from '../../redux-store/types/kyc';

interface MValues {
  pan: string;
  phone: string;
  email: string;
  dob: string;
  countryCode: string;
  countryNameAndCode: string;
}

export default function InitiateKyc(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema1 = yup.object().shape({
    pan: yup
      .string()
      .uppercase()
      .length(10, 'PAN must be exactly 10 characters')
      .test('pan', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (!fieldValue) {
          return createError({
            message: 'PAN is required',
            path,
          });
        }
        if (fieldValue.split('')[3] === 'P') {
          if (!individualPanRegex.test(fieldValue))
            return createError({
              message: 'Invalid PAN',
              path,
            });
          if (individualPanRegex.test(fieldValue)) return true;
        }
        if (!nonIndividualPanRegex.test(fieldValue)) {
          return createError({
            message: 'Invalid PAN',
            path,
          });
        }
        return true;
      }),
    dob: yup
      .string()
      .nullable()
      .test('dob', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;

        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        } else if (
          futureAge(fieldValue) ||
          fieldValue.toString().toLowerCase() === 'Invalid date'
        ) {
          return createError({
            message: 'Invalid Date',
            path,
          });
        } else if (isMinor(fieldValue) && parent.pan?.split('')[3] === 'P') {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        } else if (maxAge(fieldValue) && parent.pan?.split('')[3] === 'P') {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        return true;
      }),
    email: yup.string().test('email', (value, context) => {
      const { createError, path } = context;
      if (!value) {
        return createError({
          message: 'Email Id is required',
          path,
        });
      }
      if (!emailRegex.test(value)) {
        return createError({
          message: 'Invalid Email Id',
          path,
        });
      }
      return true;
    }),

    phone: yup.string().test('phone', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      return true;
    }),
  });
  const initialValues1: MValues = {
    pan: '',
    phone: '',
    email: '',
    dob: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
  };

  const handleSubmit1 = async (values: MValues) => {
    const {
      pan,
      dob,
      phone = '',
      email,
      // eslint-disable-next-line
      countryCode = '+91',
      countryNameAndCode = 'India: +91',
    } = values;

    (await dispatch(
      createKycApplication({
        pan,
        phone: phone.trim(),
        email,
        dob,
        countryCode: countryNameAndCode?.split(':')[1].trim(),
      })
    )) as unknown as Partial<KycApplicationResponse>;

    history.push('/kyc-applications');
  };

  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({ handleSubmit, values, isSubmitting }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <MFTextField
              label="PAN"
              name="pan"
              placeholder="Enter PAN"
              required
              inputProps={{ style: { textTransform: 'uppercase' } }}
              applyMarginBottom={false}
            />
            <TextDatePicker
              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
              label={'Date of birth'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'Enter Date of birth'}
              name="dob"
            />
            <MobileNumberField
              label={'Mobile Number'}
              name={`phone`}
              placeholder="Enter Mobile number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              countryCodeFieldName={'countryNameAndCode'}
              countryCodeValue={values.countryNameAndCode}
              regexForFilterValue={nonDigitRegex}
            />
            <MFTextField
              autoComplete="off"
              // autoFocus
              label="Email ID"
              name="email"
              placeholder="Enter Email ID"
              required
            />
            <MFSubmitButton label={'Initiate KYC'} disabled={isSubmitting} />
          </Box>
        )}
      </Formik>
    </>
  );
}
