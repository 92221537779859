import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  Broker,
  individuals_Poa_nonIndividuals_Documents,
  JointholderRelationMaster,
  Document,
} from '../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  ApplicationStatusBasedOnRole,
  BloodRelations,
  CurrencyMaster,
  defaultCurrency,
  DLCLMasters,
  ENABLE_RTA_APPROVER,
  FEE_TYPE_MASTER,
  IndividualApplicationStatusBasedOnRole,
  isNaturalParent,
  onboardingTypeMasters,
  RiskProfileEnabled,
  SALT,
  SALT2,
  SAVE_LATER_VALIDATION_CHECK,
  UboTypeMaster,
  USER_ROLES,
  YES_NO_MASTER,
} from './constant';
import { ToWords } from 'to-words';
import { individualPanRegex, nonIndividualPanRegex, numberRegex } from './regex';
import _ from 'lodash';
import { mdmsCountriesList } from '../redux-store/types/mdms';
import { updatedRiskProfile } from '../components/investors/riskProfileDetails';
import en from '../lang/en-us';
import { KycApplicationResponse } from '../redux-store/types/kyc';
import {
  document_object,
  documentProps,
  nomineeDocumentProps,
} from '../components/investors/documentDetails';
import { documentProps as nonIndDocumentProps } from '../components/NonIndividualInvestor/DocumentDetails';

export const maskMobileNumber = (mobileNumber: string): string => {
  if (!mobileNumber) {
    return '';
  }
  return (
    mobileNumber.slice(0, 2) +
    mobileNumber.slice(2, mobileNumber.length - 2).replace(/\d/g, '*') +
    mobileNumber.slice(mobileNumber.length - 2)
  );
};

export const changeGoalVal = (
  ans: string,
  riskProfileData: updatedRiskProfile[],
  question: string
): string[] => {
  const ansArray = ans.split('*').map((val) => {
    const index = val.indexOf('_');
    const result = val.substring(0, index);
    return result;
  });
  const valueArray = riskProfileData.find((profile) => profile?.question === question)?.values;
  const labelsArray = ansArray.map((key) => {
    const item = valueArray?.find((val) => val.key === key);
    return item ? `${item.label}` : key;
  });
  return labelsArray;
};

export const getApplicantName = (index: number, isNomineeOrBank?: boolean): string => {
  if (index === 1) {
    return isNomineeOrBank ? 'First' : 'First/Sole';
  } else if (index === 2) {
    return 'Second';
  } else {
    return 'Third';
  }
};

export const minDateForContributor = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const getFirstHolderName = (applicants: Partial<Applicant>[]): string => {
  return (
    applicants.find((applicant) => ['firstapplicant', '1'].includes(applicant.applicant_type || ''))
      ?.name || 'N/A'
  );
};

export const getApplicantType = (applicantType: string | undefined): string => {
  if (applicantType) {
    if (['firstapplicant', '1'].includes(applicantType)) {
      return '1';
    } else if (['secondapplicant', '2'].includes(applicantType)) {
      return '2';
    } else {
      return '3';
    }
  }
  return '';
};

export const formatToIndianCurrency = (value: number | null): string => {
  const options: { style: 'currency'; currency: 'INR' } = { style: 'currency', currency: 'INR' };
  if (value) {
    return new Intl.NumberFormat('en-IN', options).format(value);
  }
  return '';
};

export function toExactSubstr(str: string): string {
  const a = str.split('.');
  if (a.length == 2) {
    return a[0]?.concat('.', a[1].substr(0, 2));
  }
  return str;
}

export const formatCurrency = (value?: number | null): string => {
  const formats = ['K', 'L', 'Cr'];
  // eslint-disable-next-line
  let amount: any = Number(value);
  formats.some((key, i) => {
    const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
    if (size <= amount) {
      amount = toExactSubstr(`${amount / size}`);
      if (amount >= 100 && i < formats.length - 1) {
        i++;
      } else {
        // amount = (amount * decPlaces) / decPlaces;
        amount += ' ' + formats[i];
        return true;
      }
    } else {
      return true;
    }
  });
  return amount;
};

export const EIGHTEEN_YEARS_IN_MILLISECONDS = 18 * 31556926 * 1000; //  18 * no of seconds in a Year * 1000

export const isMinor = (dateOfBirth = ''): boolean => {
  return new Date().getTime() - new Date(dateOfBirth).getTime() < EIGHTEEN_YEARS_IN_MILLISECONDS;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');
export const getCountryCodes = (): { label: string; key: string; countryCode: string }[] => {
  const countryCodesObject: { [key: string]: string } = countryCodes.customList(
    'countryCode',
    '{countryNameEn}: +{countryCallingCode}'
  );

  const modifiedCodes = Object.keys(countryCodesObject).map((cc) => ({
    label: countryCodesObject[cc],
    key: countryCodesObject[cc].split(':')[1].trim(),
    countryCode: cc,
  }));
  return modifiedCodes;
};

export const getAddressFields = (addressType: string): ApplicantAddressType => {
  return {
    address1: '',
    address2: '',
    // address3: '',
    city: '',
    district: '',
    state: '',
    country: addressType === 'overseas' ? '' : 'INDIA',
    pincode: '',
    permanentAddressSameAsCorresponding: false,
    address_type: addressType,
    isActive: true,
    fetchedFromKRA: null,
    fetchedFromDigiLocker: null,
  };
};

export const getAddressData = (
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  return (
    addresses?.find((address) => address.address_type === addressType) ||
    getAddressFields(addressType)
  );
};

export function getRelation(relationValue?: string | null): boolean {
  return ['Mother', 'Father', 'Daughter', 'Son', 'Spouse', 'Brother', 'Sister', ''].includes(
    relationValue || ''
  );
}

export const saveForLater = (
  role: string,
  id?: string,
  applicant1ReferenceId?: string | null,
  isTopUp?: boolean | null
): string => {
  return role === USER_ROLES.INVESTOR
    ? `/${isTopUp ? 'topup' : 'investment-details'}/${applicant1ReferenceId}/application-details`
    : role === USER_ROLES.POAAPPROVER || role === USER_ROLES.AMCAPPROVER
    ? `/application-details/${id}`
    : isTopUp
    ? '/view-top-up-list'
    : '/applications';
};

export const maxAge = (dateOfBirth = ''): boolean => {
  const thisYear = new Date().getFullYear();
  return thisYear - new Date(dateOfBirth).getFullYear() > 125;
};
export const futureAge = (dateOfBirth = ''): boolean => {
  return new Date(dateOfBirth) > new Date();
};
export function currencyConversion(
  num?: string | number | null,
  currencyType?: string | null
): string | null {
  if (!num) return '';
  const toWords = new ToWords({
    localeCode: Object.keys(CurrencyMaster).includes(currencyType?.toLowerCase() || '')
      ? CurrencyMaster[currencyType?.toLowerCase() || defaultCurrency]?.localeCode
      : CurrencyMaster[defaultCurrency]?.localeCode,
  });
  const words = toWords.convert(Number(num), { currency: true });
  return words;
}

export const isOnboardingTypeMinor = (onBoardingType: string): boolean => {
  return onboardingTypeMasters[onBoardingType] === onboardingTypeMasters.minor;
};

export const isFatherMother = (guardianRelationship: string): boolean => {
  return ['MOTHER', 'FATHER'].includes(guardianRelationship);
};

export function checkIfApplicationIsNonIndividual({ applicationType }: ApplicationProps): boolean {
  return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL;
}

// eslint-disable-next-line
export const preventSpecialCharacters = (e: any): void => {
  if (
    !((e.ctrlKey || e.metaKey) && e.keyCode == 67) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 86) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 88) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 90) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(e.key) &&
    !numberRegex.test(e.key)
  ) {
    e.preventDefault();
  }
};
// eslint-disable-next-line
export function allowOnlyNumbers(e: any): void {
  if (['-', '+', 'e', 'E'].includes(e.key)) {
    e.preventDefault();
  }
}
export const permanentAddressSameAsCorresponding = (
  updatedCorrespondingAddress: Partial<ApplicantAddressType>,
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  // eslint-disable-next-line
  const { address1, address2, city, pincode, state, country, ...rest } =
    updatedCorrespondingAddress;
  return {
    ...getAddressData(addressType, addresses),
    address1: address1,
    address2: address2,
    city: city,
    pincode: pincode,
    state: state,
    country: country,
    address_type: addressType,
  };
};
export const _updatedAddresses = (
  updatedAddresses: {
    nationality?: string | null | undefined;
    status?: string | null | undefined;
    permanent?: Partial<ApplicantAddressType> | undefined;
    correspondence?: Partial<ApplicantAddressType> | undefined;
    overseas?: Partial<ApplicantAddressType> | undefined;
    [key: string]: string | Partial<ApplicantAddressType> | null | undefined;
  },
  applicant: Partial<Applicant>
): Partial<ApplicantAddressType>[] => {
  // eslint-disable-next-line
  const { nationality, status, ...addressProps } = updatedAddresses;
  return Object.keys(addressProps)
    .map((addressType) => {
      // if (applicant.nationality?.toLowerCase() === 'indian' && addressType === 'overseas') {
      //   return {};
      // }
      if (
        addressType === 'permanent' &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['overseas'],
          addressType,
          applicant.addresses
        );
      }
      if (
        addressType === 'permanent' &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding &&
        !addressProps['overseas']
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['correspondence'],
          addressType,
          applicant.addresses
        );
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['overseas'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['correspondence'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      return {
        ...getAddressData(addressType, applicant.addresses),
        ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
        address_type: addressType,
      };
    })
    .flat()
    .filter((address) => Object.keys(address).length);
};
export const applyRoleBasedStatus = (role: USER_ROLES): boolean => {
  return [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMCAPPROVER,
    USER_ROLES.AMC_ADMIN,
  ].includes(role);
};

export const checkKraBasedOnStatus = (status: string): boolean => {
  return ![
    APPLICATION_LISTING_STATUS.invitationexpired,
    APPLICATION_LISTING_STATUS.signed,
    APPLICATION_LISTING_STATUS.completed,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};

export const applicationComparison = (
  existingApplication: Partial<ApplicationProps> | Partial<KycApplicationResponse> | null,
  updatedApplication: Partial<ApplicationProps> | Partial<KycApplicationResponse>
): boolean => {
  return _.isEqual(existingApplication, updatedApplication);
};

export const sendApplication_Nri = (application?: ApplicationProps | null): boolean | undefined => {
  return application?.applicants.map((applicant) => applicant.status).includes('NRI');
};

export const checkForCorrespondenceAddress = (
  nationality: string | null | undefined,
  status: string | null | undefined
): boolean => {
  return (
    nationality?.toLowerCase() === 'indian' &&
    applicantStatusMasters[status as string] === applicantStatusMasters.Individual
  );
};

export const setUpFeeCalculations = (
  commitmentAmount: number,
  setupFeePercentage: number
): { feeAmount: number; feeGst: number; totalSetupFee: number } => {
  const setupFeeAmount = (Number(commitmentAmount) * Number(setupFeePercentage)) / 100;
  const GstForSetupFee = (setupFeeAmount * 18) / 100;
  return {
    feeAmount: Number(setupFeeAmount),
    feeGst: Number(GstForSetupFee),
    totalSetupFee: Number(setupFeeAmount + GstForSetupFee),
  };
};

export const checkAddressField = (data?: string): string => {
  if (data) {
    if (data.includes(' ,')) {
      return data.split(' ,').join(',') + ' ';
    }
    return data.includes(',') ? data + ' ' : data + ', ';
  }
  return '';
};

export const getdpIdField = (value: string): boolean => {
  return (
    DLCLMasters[value || ''] === DLCLMasters.cdsl || DLCLMasters[value || ''] === DLCLMasters.nsdl
  );
};

export const clearclIdField = (value: string): boolean => {
  return (
    DLCLMasters[(value as string) || ''] === DLCLMasters.none ||
    DLCLMasters[(value as string) || ''] === DLCLMasters.cdsl
  );
};

export const cleardpIdField = (value: string): boolean => {
  return DLCLMasters[(value as string) || ''] === DLCLMasters.none;
};

export const getclIdField = (value: string): boolean => {
  return DLCLMasters[value || ''] === DLCLMasters.nsdl;
};

export const isCDSL = (value: string): boolean => {
  return DLCLMasters[(value as string) || ''] === DLCLMasters.cdsl;
};

export const getBankAddress = (
  address1: string | undefined,
  address2: string | undefined,
  address3: string | undefined
): string => {
  return `${address2 ? checkAddressField(address1) || '' : address1 || '' || ''}${
    address3 ? checkAddressField(address2) || '' : address2 || ''
  }${address3 || ''}`;
};
// eslint-disable-next-line
export const numToAlpMonth = (numMonth: any, month: any) => {
  // eslint-disable-next-line
  numMonth?.map((monthNum: any) => {
    if (monthNum === 1) {
      month.push('Jan');
    } else if (monthNum === 2) {
      month.push('Feb');
    } else if (monthNum === 3) {
      month.push('Mar');
    } else if (monthNum === 4) {
      month.push('Apr');
    } else if (monthNum === 5) {
      month.push('May');
    } else if (monthNum === 6) {
      month.push('Jun');
    } else if (monthNum === 7) {
      month.push('Jul');
    } else if (monthNum === 8) {
      month.push('Aug');
    } else if (monthNum === 9) {
      month.push('Sep');
    } else if (monthNum === 10) {
      month.push('Oct');
    } else if (monthNum === 11) {
      month.push('Nov');
    } else if (monthNum === 12) {
      month.push('Dec');
    }
  });
};
// eslint-disable-next-line
export const removeSingleQuote = (value: string | null | undefined): any => {
  if (value && value.includes("'")) return value.split("'").join('');
  else return value;
};

export function removeEqualOperator(value = ''): string {
  const lastTwoIndex = value.length - 2;
  let encodeString = value.slice(0, lastTwoIndex);
  const slicedStr = value.slice(lastTwoIndex, value.length);
  slicedStr.split('').forEach((char) => {
    if (char !== '=') {
      encodeString += char;
    }
  });
  return encodeString;
}

export function encodeBase64(value: string): string {
  const encodeValue = btoa(value);
  const encodeSubString = removeEqualOperator(encodeValue);
  const encodePayload = btoa(`${SALT}${encodeSubString}${SALT2}`);
  return encodePayload;
}

export function getNomineeRelation(relationValue?: string | null): boolean {
  return ['MOTHER', 'FATHER', 'DAUGHTER', 'SON', 'SPOUSE', 'BROTHER', 'SISTER', ''].includes(
    relationValue || ''
  );
}

export function updatedCommitmentAmount(
  minCommitmentAmount: number,
  applicants: Partial<Applicant>[],
  data: JointholderRelationMaster
): number | void {
  if (applicants.length === 2) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele)
      ?.toString();
    const relation = BloodRelations.includes(applicantRelation) ? applicantRelation : 'Others';
    const amount = data.singleJointHolder
      .map((jointHolder) => {
        if (jointHolder.jointHolder1RelationShip === relation) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
  if (applicants.length === 3) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele);
    const relation = applicantRelation.map((_relation) =>
      BloodRelations.includes(_relation as string) ? _relation : 'Others'
    );
    const amount = data.twoJointHolders
      .map((jointHolder) => {
        if (
          relation[applicants.length - 3] === jointHolder.jointHolder1RelationShip &&
          relation[applicants.length - 2] === jointHolder.jointHolder2RelationShip
        ) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
}

export function applicationDownloadStatusCheck(status: string): boolean {
  return APPLICATION_LISTING_STATUS[status] !== APPLICATION_LISTING_STATUS.completed;
}

export const checkUBOTypeIsTrust = (typeOfUBO: string): boolean => {
  return [UboTypeMaster.notApplicable, UboTypeMaster.indeterminateTrust].includes(
    UboTypeMaster[typeOfUBO]
  );
};

export const isValidPan = (pan: string): boolean =>
  [individualPanRegex, nonIndividualPanRegex].some((pattern) => pattern.test(pan));

export const getArrayLength = (arrayList: number): number[] => {
  return Array.from(
    {
      length: arrayList,
    },
    (value, index) => index + 1
  );
};

export function checkIfApplicationIsNonIndividualPOA(application: ApplicationProps): boolean {
  if (application) {
    const { applicationType, hasPOA } = application;
    return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL && hasPOA;
  } else return false;
}

export function isJson(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const checkValidationBasedOnDate = (
  date: string | number | Date,
  validationStartDate: string
): boolean => {
  if (!date) return false;
  if (!validationStartDate) return true;
  const dateForCompare = date ? new Date(date) : new Date();
  const dateForApplyValidations = new Date(validationStartDate || '');
  return dateForCompare.getTime() >= dateForApplyValidations.getTime();
};

export const checkNriPIO = (status: string, statusSubType: string): boolean => {
  return (
    applicantStatusMasters[status as string] === applicantStatusMasters.NRI &&
    applicantStatusMasters[statusSubType as string] === applicantStatusMasters.NRI_PIO
  );
};

export const checkNormalNri = (status: string, statusSubType?: string | null): boolean => {
  return applicantStatusMasters[status as string] === applicantStatusMasters.NRI && !statusSubType;
};

export const getCountryBasedOnStatus = (
  nationalitiesMdmsResponse: mdmsCountriesList[],
  status: string
): mdmsCountriesList[] => {
  return nationalitiesMdmsResponse.filter((ele) => {
    if (applicantStatusMasters[status] === applicantStatusMasters.NRI_PIO) {
      return !['PK', 'NP', 'AF', 'BD', 'CN', 'IQ', 'BT', 'LK'].includes(ele.code || '');
    }
    return ele;
  });
};

export const getSideNavComponent = (navOptions: string[]): string[] => {
  return !RiskProfileEnabled
    ? navOptions.filter((e) => !['risk-profiles', 'Risk Profiles'].includes(e))
    : navOptions;
};

export const ShowRiskDoc = (documentType: string): boolean => {
  return ['riskProfile', 'riskDeviationLetter', 'riskDisclosure'].includes(documentType);
};

export const RTAApproverEnableCheckForIndividual = (): boolean =>
  ENABLE_RTA_APPROVER && AMC_APPROVER_CHECK_FOR_INDIVIDUAL;

export const ApplicationStatusOrClassBasedOnRole = (
  application: ApplicationProps,
  role: USER_ROLES
): string => {
  return application.hasPOA || checkIfApplicationIsNonIndividual(application)
    ? [USER_ROLES.AMCAPPROVER, USER_ROLES.RTAAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
      ? ApplicationStatusBasedOnRole[role][application.status]
      : APPLICATION_LISTING_STATUS[application.status]
    : !checkIfApplicationIsNonIndividual(application) &&
      [USER_ROLES.AMCAPPROVER, USER_ROLES.RTAAPPROVER].includes(role)
    ? IndividualApplicationStatusBasedOnRole[role][application.status]
    : APPLICATION_LISTING_STATUS[application.status];
};

export const MakerOrAdmin = (role: USER_ROLES): boolean =>
  [USER_ROLES.AMC_ADMIN, USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(
    role
  );

export const RTAApproverLoggedIn = (role: USER_ROLES): boolean => role === USER_ROLES.RTAAPPROVER;
export const AMCApproverLoggedIn = (role: USER_ROLES): boolean => role === USER_ROLES.AMCAPPROVER;

export const Maker = (role: USER_ROLES): boolean =>
  [USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role);

export const statusRoleBased = (role: USER_ROLES, application: ApplicationProps | null): string => {
  return Maker(role) &&
    (application?.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ||
      AMC_APPROVER_CHECK_FOR_INDIVIDUAL)
    ? 'draft'
    : application?.status || '';
};

export const isRtaSendBackToMaker = (value?: string): boolean => value === 'maker';

export const isGetLinkVisible = (status: string): boolean =>
  ![
    'draft',
    'rejected',
    'sent_to_poa_approver',
    'sent_to_amc_approver',
    'sent_to_rta_approver',
  ].includes(status);

export const displaySendBack = (application: ApplicationProps): string => {
  return application.applicationSentBack ||
    (application.rtaSentBack &&
      APPLICATION_LISTING_STATUS[application.status] ===
        APPLICATION_LISTING_STATUS.sent_to_amc_approver)
    ? '(Send Back)'
    : '';
};

export function checkIfApplicationIsIndividualPOA(application: ApplicationProps): boolean {
  if (application) {
    const { applicationType, hasPOA } = application;
    return applicationType === APPLICATION_TYPE.INDIVIDUAL && hasPOA;
  } else return false;
}

export function shouldValidateUponSaveLater(saveType: string): boolean {
  return (
    (saveType === 'save for later' && SAVE_LATER_VALIDATION_CHECK) || saveType !== 'save for later'
  );
}

export function getStep(currentStep: number, isSaveLater: boolean): number {
  return !SAVE_LATER_VALIDATION_CHECK && isSaveLater ? currentStep - 1 : currentStep;
}

export function otherNameOfBroker(nameOfBroker: string): boolean {
  return nameOfBroker?.toLocaleLowerCase() === 'others';
}

export function nameOfBrokerExists(brokerList: Broker[], nameOfBroker: string): boolean {
  return brokerList?.map((broker) => broker.key).includes(nameOfBroker || '');
}

export const sortListKeepOthersLast = (brokerList: Broker[] = []): Broker[] => {
  // Separate "Others" from the rest of the items
  const others = brokerList.filter((item) => item.key === 'others');
  const rest = brokerList
    .filter((item) => item.key !== 'others')
    .sort((a, b) => a.key?.localeCompare(b.key));

  // Sort the rest of the items and append "Others" at the end
  return [...rest.sort(), ...others];
};

export function isAccreditedInvestorApplicable(accreditedInvestorApplicable: string): boolean {
  return accreditedInvestorApplicable?.toLowerCase() === 'yes';
}

export function isFieldApplicable(value: string): boolean {
  return YES_NO_MASTER[value?.toLowerCase()] === YES_NO_MASTER.yes;
}

export function isFieldVariable(FeeTypeValue: string | null, fieldApplicable: string): boolean {
  return (
    FEE_TYPE_MASTER[FeeTypeValue?.toLowerCase() || ''] === FEE_TYPE_MASTER.variable &&
    YES_NO_MASTER[fieldApplicable?.toLowerCase()] === YES_NO_MASTER.yes
  );
}

export const isApplicationStatusComplete = (application: ApplicationProps): boolean => {
  return (
    APPLICATION_LISTING_STATUS[application?.status] === APPLICATION_LISTING_STATUS.completed &&
    !!Number(application.folio_no)
  );
};

export const checkSupportLogin = (createdBy: string | number, id: string | number): boolean => {
  return Number(createdBy) === Number(id);
};

export const checkSubDisDisable = (
  role: USER_ROLES,
  createdBy: string | number,
  id: string | number
): boolean => {
  return (
    [
      USER_ROLES.DISTRIBUTOR,
      USER_ROLES.INVESTOR,
      USER_ROLES.POAAPPROVER,
      USER_ROLES.AMCAPPROVER,
      USER_ROLES.RTAAPPROVER,
      USER_ROLES.AMC_ADMIN,
    ].includes(role) ||
    (USER_ROLES.SUBDISTRIBUTOR === role && !checkSupportLogin(Number(createdBy), Number(id)))
  );
};

/**
 * Replace with your file URL or API endpoint
 * @param fileUrl
 *
 * Desired file name
 * @param fileName
 */
export const handleDownload = async (fileUrl: string, fileName: string): Promise<void> => {
  // Fetch the file data

  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(en.networkText.unableToProcess);
  }
  const blob = (await response.blob()) as Blob;

  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Clean up
  link.remove();
  URL.revokeObjectURL(blobUrl);
};

export const isApplicantsVerified = (application: ApplicationProps): boolean => {
  return !application.applicants.some((applicant) => applicant.kraVerified === 'no');
};

export const enableComplianceForTopUp = (docType: string): boolean =>
  docType !== 'compliance_document';

export const decodeState = (
  stateValue = ''
): null | { digiLockerAmcCode: string; esignReferenceId: string; token: string; role: string } => {
  if (!stateValue) return null;
  return JSON.parse(atob(stateValue));
};

export const roleApplicableForKycPreview = (role: USER_ROLES): boolean =>
  [
    USER_ROLES.AMC_ADMIN,
    USER_ROLES.RM,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.INVESTOR,
  ].includes(role);

export function getDocNumberForMultipleDocs(docArraylength: number): string | null {
  switch (docArraylength.toString()) {
    case '1':
      return 'first ';
    case '2':
      return 'second ';
    case '3':
      return 'third ';
    case '4':
      return 'forth';
    default:
      return '';
  }
}

export const nonIndividualDocuments = (
  application: ApplicationProps | null,
  role: USER_ROLES,
  documentsData: individuals_Poa_nonIndividuals_Documents[]
): nonIndDocumentProps[] => {
  const { applicants = [] } = application || {};
  return applicants.map((applicant, applicant_index) => {
    return {
      documents: documentsData
        .filter((doc) => {
          if (
            checkIfApplicationIsNonIndividualPOA(application as ApplicationProps) &&
            applicant.applicant_type !== '1' &&
            doc.documentType === 'poaNotarized'
          ) {
            return;
          }
          if (!RiskProfileEnabled && ShowRiskDoc(doc.documentType)) {
            return;
          }
          return doc;
        })
        .filter((ele) => ele)
        .map((_doc) => {
          if (role === USER_ROLES.POAAPPROVER && _doc.documentType === 'poaNotarized') {
            return { ..._doc, required: 'true' };
          }
          if (RiskProfileEnabled && ShowRiskDoc(_doc.documentType)) {
            return { ..._doc, required: 'true' };
          }
          return _doc;
        })
        .filter((document) => {
          if (applicant.amlCheck && document.documentType === 'compliance_document') {
            return;
          }
          return document;
        })
        .filter((ele) => ele)
        .map((_doc) => {
          if (
            applicant?.dlclId &&
            !cleardpIdField(applicant?.dlclId || '') &&
            _doc.documentType === 'demat_information'
          ) {
            return { ..._doc, required: 'true' };
          }
          return _doc;
        })
        .map((doc, index) => {
          const { documentType, documentName, multipleFiles, required, options } = doc;
          const { documents: existingDocuments = [] } = applicant || {};
          const docsOfCurrentDocType = existingDocuments
            .filter((doc) => doc.documentType === documentType)
            .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
          return {
            documentType: documentType,
            documentName: documentName,
            documentsList: docsOfCurrentDocType.length
              ? docsOfCurrentDocType.map((doc, ind) => {
                  const {
                    id,
                    documentName = '',
                    documentType = '',
                    documentId = '',
                    isActive = true,
                    file = undefined,
                  } = doc;
                  return {
                    id,
                    applicantId: applicant?.id,
                    documentType,
                    documentName,
                    documentId,
                    isActive,
                    file,
                    options,
                    required,
                    isVisible: true,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      ind.toString()) as string,
                  };
                })
              : [
                  {
                    documentType,
                    documentName,
                    required,
                    options,
                    isVisible: true,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      '0') as string,
                    ...document_object,
                  },
                ],
            required,
            multipleFiles,
            options,
          };
        }),
      kraDocument: applicant.kraDocument,
    };
  });
};

export const individualDocuments = (
  application: ApplicationProps | null,
  role: USER_ROLES,
  documentsData?: Document | string
): documentProps[] => {
  const { applicants = [], hasPOA = false } = application || {};
  return applicants.map((applicant, applicant_index) => {
    let docData = '';
    if (
      !hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
    ) {
      if (
        onboardingTypeMasters[applicants[0].onboardingType as string] ===
        onboardingTypeMasters.minor
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.MINOR;
      } else {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
      }
    }
    if (
      hasPOA &&
      applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
    ) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
    }
    if (!hasPOA && checkNormalNri(applicant.status as string, applicant.statusSubType)) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
    }
    if (hasPOA && checkNormalNri(applicant.status as string, applicant.statusSubType)) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
    }
    if (!hasPOA && checkNriPIO(applicant.status as string, applicant.statusSubType as string)) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_PIO;
    }
    if (hasPOA && checkNriPIO(applicant.status as string, applicant.statusSubType as string)) {
      docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA_PIO;
    }
    return {
      documents:
        typeof documentsData !== 'undefined'
          ? ((documentsData as Document)[docData] || [])
              .filter((doc) => {
                if (
                  hasPOA &&
                  applicant.applicant_type !== '1' &&
                  doc.documentType === 'poaNotarized'
                ) {
                  return;
                }
                if (
                  isNaturalParent[applicant.naturalParent as string] !== isNaturalParent.no &&
                  doc.documentType === 'proofOfCourt'
                ) {
                  return;
                }
                if (!RiskProfileEnabled && ShowRiskDoc(doc.documentType)) {
                  return;
                }
                return doc;
              })
              .filter((ele) => ele)
              .map((_doc) => {
                if (role === USER_ROLES.POAAPPROVER && _doc.documentType === 'poaNotarized') {
                  return { ..._doc, required: 'true' };
                }
                if (
                  isNaturalParent[applicant.naturalParent as string] === isNaturalParent.no &&
                  _doc.documentType === 'proofOfCourt'
                ) {
                  return { ..._doc, required: 'true' };
                }
                if (RiskProfileEnabled && ShowRiskDoc(_doc.documentType)) {
                  return { ..._doc, required: 'true' };
                }
                return _doc;
              })
              .filter((document) => {
                if (applicant.amlCheck && document.documentType === 'compliance_document') {
                  return;
                }
                return document;
              })
              .filter((ele) => ele)
              .filter((doc) => {
                if (
                  !applicant.correspondenceAddressEdited &&
                  doc.documentType === 'correspondenceAddress'
                ) {
                  return;
                }
                return doc;
              })
              .filter((ele) => ele)
              .map((doc) => {
                if (
                  applicant.correspondenceAddressEdited &&
                  doc.documentType === 'correspondenceAddress'
                ) {
                  return { ...doc, required: 'true' };
                }
                return doc;
              })
              .map((_doc) => {
                if (
                  applicant?.dlclId &&
                  !cleardpIdField(applicant?.dlclId || '') &&
                  _doc.documentType === 'demat_information'
                ) {
                  return { ..._doc, required: 'true' };
                }
                return _doc;
              })
              .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { documents: existingDocuments = [] } = applicant || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? docsOfCurrentDocType.map((doc, ind) => {
                        const {
                          id,
                          documentName = '',
                          documentType = '',
                          documentId = '',
                          isActive = true,
                          file = undefined,
                        } = doc;
                        return {
                          id,
                          applicantId: applicant?.id,
                          documentType,
                          documentName,
                          documentId,
                          isActive,
                          file,
                          options,
                          required,
                          isVisible: true,
                          uniqueKey: (applicant_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            ind.toString()) as string,
                        };
                      })
                    : [
                        {
                          documentType,
                          documentName,
                          required,
                          options,
                          isVisible: true,
                          uniqueKey: (applicant_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '0') as string,
                          ...document_object,
                        },
                      ],
                  required,
                  multipleFiles,
                  options,
                };
              })
          : [],
      kraDocument: applicant.kraDocument,
    };
  });
};

export const nomineesDocuments = (
  application: ApplicationProps | null,
  documentsData?: Document | string
): nomineeDocumentProps[] => {
  const { nominees = [] } = application || {};
  return nominees.map((nominee, nominee_index) => {
    return {
      nomineedocuments:
        typeof documentsData !== 'undefined'
          ? (isMinor(nominee.dateOfBirth || '')
              ? [
                  ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                    []),
                  ...((documentsData as Document)[
                    APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                  ] || []),
                ].map((doc) => {
                  if (doc.documentType === 'nomineeIdProof') {
                    return { ...doc, required: 'false' };
                  }
                  return doc;
                })
              : (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
            ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
              const { documentType, documentName, multipleFiles, required, options } = doc;
              const { nomineedocuments: existingDocuments = [] } = nominee || {};
              const docsOfCurrentDocType = existingDocuments
                .filter((doc) => doc.documentType === documentType)
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
              return {
                documentType: documentType,
                documentName: documentName,
                documentsList: docsOfCurrentDocType.length
                  ? docsOfCurrentDocType.map((doc, ind) => {
                      const {
                        id,
                        documentName = '',
                        documentType = '',
                        documentId = '',
                        isActive = true,
                        file = undefined,
                      } = doc;
                      return {
                        id,
                        nomineeId: nominee?.id,
                        documentType,
                        documentName,
                        documentId,
                        isActive,
                        file,
                        options,
                        required,
                        isVisible: true,
                        uniqueKey: (nominee_index.toString() +
                          '-' +
                          index.toString() +
                          '-' +
                          ind.toString()) as string,
                      };
                    })
                  : [
                      {
                        documentType,
                        documentName,
                        required,
                        options,
                        isVisible: true,
                        uniqueKey: (nominee_index.toString() +
                          '-' +
                          index.toString() +
                          '-' +
                          '0') as string,
                        ...document_object,
                      },
                    ],
                required,
                multipleFiles,
                options,
              };
            })
          : [],
    };
  });
};
